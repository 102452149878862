import * as React from "react";

function SvgIconCursor(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={24}
      height={24}
      {...props}
    >
      <linearGradient
        id="IconCursor_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={18.1}
        x2={49}
        y2={83.9}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M79.9 40.3l-59.8-22c-1.1-.4-2.3-.1-3.1.7-.8.8-1.1 2.1-.7 3.2l22 59.8c.4 1.1 1.5 1.9 2.7 2h.2c1.1 0 2.2-.7 2.7-1.7l10.6-21.6 20.8 20.8 4.2-4.2-20.9-20.9 21.6-10.6c1.1-.5 1.7-1.6 1.7-2.8-.1-1.3-.8-2.3-2-2.7zM52.2 52.8c-.6.3-1.1.8-1.4 1.4l-9.3 19.1-17.3-47.1 47.1 17.3-19.1 9.3z"
        fill="url(#IconCursor_svg__a)"
      />
    </svg>
  );
}

export default SvgIconCursor;
