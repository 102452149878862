import React, {useState} from 'react'

export const themes =
{
    primaryColor: {
        light: 'black',
        dark: 'white',
    },
    primaryBackgroundColor : {
        'light' : '#fff',
        'dark' : '#18191a'
    }
};

export const getTheme = (mode) =>
{
    let Theme = {};

    for (let key in themes)
    {
        Theme[key] = themes[key][mode];
    }

    return Theme;
};

export const ManageThemeContext = React.createContext({
    mode: 'dark',
    theme: getTheme('dark'),
    toggleTheme: () => { }
});

export const useTheme = () => React.useContext(ManageThemeContext);

export const ThemeManager = ({children}) =>
{
    let [currentThemeName, setCurrentThemeName] = useState('dark');

    async function toggleTheme ()
    {
        setCurrentThemeName(currentThemeName === 'light' ? 'dark' : 'light');
    }

    return (
        <ManageThemeContext.Provider value={{
            themeName : currentThemeName,
            theme: getTheme(currentThemeName),
            toggleTheme: toggleTheme
        }}>
            {children}
        </ManageThemeContext.Provider>
    )
};

export default ThemeManager;
