import * as React from "react";

function SvgIconBorderWidth(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={512}
      height={512}
      {...props}
    >
      <linearGradient
        id="IconBorderWidth_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={18.599}
        x2={49}
        y2={83.401}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M88.5 48h-14c-1.1 0-2.2.6-2.7 1.6L60 72.8 41.3 20.6c-.4-1.1-1.4-1.9-2.6-2-1.2-.1-2.3.6-2.9 1.6L21.7 48H9.5c-1.7 0-3 1.3-3 3s1.3 3 3 3h14c1.1 0 2.2-.6 2.7-1.6L38 29.2l18.7 52.3c.4 1.1 1.4 1.9 2.6 2h.2c1.1 0 2.2-.6 2.7-1.6L76.3 54h12.2c1.7 0 3-1.3 3-3s-1.3-3-3-3z"
        fill="url(#IconBorderWidth_svg__a)"
      />
    </svg>
  );
}

export default SvgIconBorderWidth;
