import * as React from "react";

function SvgIconTriangle(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <linearGradient
        id="IconTriangle_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={146.381}
        y1={620.956}
        x2={146.381}
        y2={108.956}
        gradientTransform="rotate(-90 146.808 255.573)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M230.3 58.8L5.7 421.5c-6.3 9.5-6.3 22.1-1.1 31.6C10 463.7 20.5 469 32.1 469h449.1c11.6 0 22.1-6.3 27.4-15.8 3.2-5.3 4.2-10.5 4.2-15.8 0-5.3-2.1-11.6-4.2-16.9L284.1 57.8c-5.3-9.5-15.8-14.8-27.4-14.8-11.6 0-20.1 6.4-26.4 15.8zm195.1 348H90.1l167.6-271 167.7 271z"
        fill="url(#IconTriangle_svg__a)"
      />
    </svg>
  );
}

export default SvgIconTriangle;
