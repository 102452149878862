import {shallowEqual, useSelector} from "react-redux";
import {fabric} from "fabric";
import {useCallback, useEffect, useState} from "react";
import {setGuidelines, setGuidelinesVisibility, setSelectedGuideline} from "../ducks/guidelines";

const ALIGNING_LINE_OFFSET = 5;
const ALIGNING_LINE_MARGIN = 15;

export function useAligningLogic ()
{
	const canvas = useSelector(state => state.canvas.canvas, shallowEqual);

	const guidelines = useSelector(state => state.guidelines.guidelines, shallowEqual);
	const guidelinesVisibility = useSelector(state => state.guidelines.guidelinesVisibility, shallowEqual);
	const selectedGuideline = useSelector(state => state.guidelines.selectedGuideline, shallowEqual);
	const zoom = useSelector(state => state.canvas.zoom, shallowEqual);

	/**
	 * save click position when user clicks mouse right button
	 * to possible add guideline at this point
	 * due to external setState, we store it in state to prevent drop to 0
	 */
	const [clickX, setClickX] = useState(0);
	const [clickY, setClickY] = useState(0);

	let horizontalLines = [];
	let verticalLines = [];

	/**
	 * store viewport to transform lines in accordance with current zoom / scale / etc
	 * we store in locally to prevent multiple set state
	 * also after initialization this value will drop on each setState (due to setActiveObject for example), so we get initial value from canvas
	 */
	let viewportTransform = canvas && canvas.viewportTransform || null;

	function drawVerticalLine (coords)
	{
		drawLine(
			coords.x + 0.5,
			coords.y1 > coords.y2 ? coords.y2 : coords.y1,
			coords.x + 0.5,
			coords.y2 > coords.y1 ? coords.y2 : coords.y1
		);
	}

	function drawHorizontalLine (coords)
	{
		drawLine(
			coords.x1 > coords.x2 ? coords.x2 : coords.x1,
			coords.y + 0.5,
			coords.x2 > coords.x1 ? coords.x2 : coords.x1,
			coords.y + 0.5
		);
	}

	function drawLine (x1, y1, x2, y2)
	{

		let ctx = canvas.getSelectionContext();
		ctx.save();
		ctx.lineWidth = 1.5 / canvas.getZoom();
		ctx.strokeStyle = selectedGuideline && (selectedGuideline.x === x1 || selectedGuideline.y === y1) ? '#25F7F0' : '#f03678';

		let v = viewportTransform;
		if (v === null)
		{
			return;
		}

		ctx.transform(v[0], v[1], v[2], v[3], v[4], v[5]);
		ctx.beginPath();

		ctx.moveTo(x1, y1);
		ctx.lineTo(x2, y2);

		ctx.stroke();
		ctx.restore();
	}

	/**
	 * check if 2 coordinates can be snapped
	 */
	function isInRange (value1, value2)
	{
		value1 = Math.round(value1);
		value2 = Math.round(value2);

		for (let i = value1 - ALIGNING_LINE_MARGIN, len = value1 + ALIGNING_LINE_MARGIN; i <= len; i++)
		{
			if (i === value2)
			{
				return true;
			}
		}

		return false;
	}

	/**
	 * calc aligning lines logic
	 */
	const onObjectMoveAligning = (e) =>
	{
		let activeObject = e.target,
			canvasObjects = canvas.getObjects(),
			activeObjectCenter = activeObject.getCenterPoint(),
			activeObjectLeft = activeObjectCenter.x,
			activeObjectTop = activeObjectCenter.y,
			activeObjectBoundingRect = activeObject.getBoundingRect(),
			activeObjectHeight = activeObject.getScaledHeight(),
			activeObjectWidth = activeObject.getScaledWidth(),
			horizontalInTheRange = false,
			verticalInTheRange = false,
			transform = canvas._currentTransform;

		if (!transform) return;

		// It should be trivial to DRY this up by encapsulating (repeating) creation of x1, x2, y1, and y2 into functions,
		// but we're not doing it here for perf. reasons -- as this a function that's invoked on every mouse move

		let snapX, snapY;

		for (let i = canvasObjects.length; i--; )
		{
			if (canvasObjects[i] === activeObject || canvasObjects[i].name === 'arrowLineTriangle' || canvasObjects[i].name === 'linePoint')
			{
				continue;
			}

			if (activeObject.name === 'linePoint' && canvasObjects[i].type === 'rotatingLine' && activeObject.lineId !== undefined && activeObject.lineId === canvasObjects[i].id)
			{
				continue;
			}

			let objectCenter = canvasObjects[i].getCenterPoint(),
				objectLeft = objectCenter.x,
				objectTop = objectCenter.y,
				objectHeight = canvasObjects[i].getScaledHeight(),
				objectWidth = canvasObjects[i].getScaledWidth();

			// snap by the vertical center line
			if (isInRange(objectLeft, activeObjectLeft))
			{
				verticalInTheRange = true;
				verticalLines.push({
					x: objectLeft,
					y1: Math.min(activeObject.top, canvasObjects[i].top),
					y2: Math.max(activeObject.top + activeObjectHeight, canvasObjects[i].top + objectHeight)
				});

				snapX = objectLeft;
			}
			// snap by horizontal center line
			if (isInRange(objectTop, activeObjectTop))
			{
				horizontalInTheRange = true;
				horizontalLines.push({
					y: objectTop,
					x1: Math.min(activeObject.left, canvasObjects[i].left),
					x2: Math.max(activeObject.left + activeObjectWidth, canvasObjects[i].left + objectWidth)
				});

				snapY = objectTop;
			}

			// snap by the left-left edge
			if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {

				verticalInTheRange = true;
				verticalLines.push({
					x: objectLeft - objectWidth / 2,
					y1: (objectTop < activeObjectTop)
						? (objectTop - objectHeight / 2 - ALIGNING_LINE_OFFSET)
						: (objectTop + objectHeight / 2 + ALIGNING_LINE_OFFSET),
					y2: (objectTop < activeObjectTop)
						? (activeObjectTop + activeObjectHeight / 2 + ALIGNING_LINE_OFFSET)
						: (activeObjectTop - activeObjectHeight / 2 - ALIGNING_LINE_OFFSET)
				});

				snapX = objectLeft - objectWidth / 2 + activeObjectWidth / 2;
			}

			// snap by the left-right edge
			if (isInRange(objectLeft - objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {

				verticalInTheRange = true;
				verticalLines.push({
					x: objectLeft - objectWidth / 2,
					y1: (objectTop < activeObjectTop)
						? (objectTop - objectHeight / 2 - ALIGNING_LINE_OFFSET)
						: (objectTop + objectHeight / 2 + ALIGNING_LINE_OFFSET),
					y2: (objectTop < activeObjectTop)
						? (activeObjectTop + activeObjectHeight / 2 + ALIGNING_LINE_OFFSET)
						: (activeObjectTop - activeObjectHeight / 2 - ALIGNING_LINE_OFFSET)
				});

				snapX = objectLeft - objectWidth / 2 - activeObjectWidth / 2;
			}

			// snap by the right-right edge
			if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft + activeObjectWidth / 2)) {
				verticalInTheRange = true;
				verticalLines.push({
					x: objectLeft + objectWidth / 2,
					y1: (objectTop < activeObjectTop)
						? (objectTop - objectHeight / 2 - ALIGNING_LINE_OFFSET)
						: (objectTop + objectHeight / 2 + ALIGNING_LINE_OFFSET),
					y2: (objectTop < activeObjectTop)
						? (activeObjectTop + activeObjectHeight / 2 + ALIGNING_LINE_OFFSET)
						: (activeObjectTop - activeObjectHeight / 2 - ALIGNING_LINE_OFFSET)
				});

				snapX = objectLeft + objectWidth / 2 - activeObjectWidth / 2;
			}

			// snap by the right-left edge
			if (isInRange(objectLeft + objectWidth / 2, activeObjectLeft - activeObjectWidth / 2)) {
				verticalInTheRange = true;
				verticalLines.push({
					x: objectLeft + objectWidth / 2,
					y1: (objectTop < activeObjectTop)
						? (objectTop - objectHeight / 2 - ALIGNING_LINE_OFFSET)
						: (objectTop + objectHeight / 2 + ALIGNING_LINE_OFFSET),
					y2: (objectTop < activeObjectTop)
						? (activeObjectTop + activeObjectHeight / 2 + ALIGNING_LINE_OFFSET)
						: (activeObjectTop - activeObjectHeight / 2 - ALIGNING_LINE_OFFSET)
				});

				snapX = objectLeft + objectWidth / 2 + activeObjectWidth / 2;
			}

			// snap by the top-top edge
			if (isInRange(objectTop - objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
				horizontalInTheRange = true;
				horizontalLines.push({
					y: objectTop - objectHeight / 2,
					x1: (objectLeft < activeObjectLeft)
						? (objectLeft - objectWidth / 2 - ALIGNING_LINE_OFFSET)
						: (objectLeft + objectWidth / 2 + ALIGNING_LINE_OFFSET),
					x2: (objectLeft < activeObjectLeft)
						? (activeObjectLeft + activeObjectWidth / 2 + ALIGNING_LINE_OFFSET)
						: (activeObjectLeft - activeObjectWidth / 2 - ALIGNING_LINE_OFFSET)
				});

				snapY = objectTop - objectHeight / 2 + activeObjectHeight / 2;
			}

			// snap by the top-bottom edge
			if (isInRange(objectTop + objectHeight / 2, activeObjectTop - activeObjectHeight / 2)) {
				horizontalInTheRange = true;
				horizontalLines.push({
					y: objectTop + objectHeight / 2,
					x1: (objectLeft < activeObjectLeft)
						? (objectLeft - objectWidth / 2 - ALIGNING_LINE_OFFSET)
						: (objectLeft + objectWidth / 2 + ALIGNING_LINE_OFFSET),
					x2: (objectLeft < activeObjectLeft)
						? (activeObjectLeft + activeObjectWidth / 2 + ALIGNING_LINE_OFFSET)
						: (activeObjectLeft - activeObjectWidth / 2 - ALIGNING_LINE_OFFSET)
				});

				snapY = objectTop + objectHeight / 2 + activeObjectHeight / 2;
			}

			// snap by the bottom-bottom edge
			if (isInRange(objectTop + objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
				horizontalInTheRange = true;
				horizontalLines.push({
					y: objectTop + objectHeight / 2,
					x1: (objectLeft < activeObjectLeft)
						? (objectLeft - objectWidth / 2 - ALIGNING_LINE_OFFSET)
						: (objectLeft + objectWidth / 2 + ALIGNING_LINE_OFFSET),
					x2: (objectLeft < activeObjectLeft)
						? (activeObjectLeft + activeObjectWidth / 2 + ALIGNING_LINE_OFFSET)
						: (activeObjectLeft - activeObjectWidth / 2 - ALIGNING_LINE_OFFSET)
				});

				snapY = objectTop + objectHeight / 2 - activeObjectHeight / 2;
			}

			// snap by the bottom-top edge
			if (isInRange(objectTop - objectHeight / 2, activeObjectTop + activeObjectHeight / 2)) {
				horizontalInTheRange = true;
				horizontalLines.push({
					y: objectTop - objectHeight / 2,
					x1: (objectLeft < activeObjectLeft)
						? (objectLeft - objectWidth / 2 - ALIGNING_LINE_OFFSET)
						: (objectLeft + objectWidth / 2 + ALIGNING_LINE_OFFSET),
					x2: (objectLeft < activeObjectLeft)
						? (activeObjectLeft + activeObjectWidth / 2 + ALIGNING_LINE_OFFSET)
						: (activeObjectLeft - activeObjectWidth / 2 - ALIGNING_LINE_OFFSET)
				});

				snapY = objectTop - objectHeight / 2 - activeObjectHeight / 2;
			}
		}

		if (snapX && !snapY)
		{
			snapY = activeObjectTop;
		}
		else if (!snapX && snapY)
		{
			snapX = activeObjectLeft;
		}

		if (snapX && snapY)
		{
			activeObject.setPositionByOrigin(new fabric.Point(snapX, snapY), 'center', 'center');
		}

		if (!horizontalInTheRange) {
			horizontalLines.length = 0;
		}

		if (!verticalInTheRange) {
			verticalLines.length = 0;
		}

		if (!guidelinesVisibility)
		{
			return;
		}

		let snapXGuidelines, snapYGuidelines;

		for (let i = guidelines.length; i--; )
		{
			// snap by the vertical center line
			if (isInRange(guidelines[i].x, activeObjectLeft))
			{
				snapXGuidelines = guidelines[i].x;
			}

			// snap by horizontal center line
			if (isInRange(guidelines[i].y, activeObjectTop))
			{
				snapYGuidelines = guidelines[i].y;
			}

			// snap by the left edge
			if (isInRange(guidelines[i].x, activeObjectLeft - activeObjectWidth / 2))
			{
				snapXGuidelines = guidelines[i].x + activeObjectWidth / 2;
			}

			// snap by the right edge
			if (isInRange(guidelines[i].x, activeObjectLeft + activeObjectWidth / 2))
			{
				snapXGuidelines = guidelines[i].x - activeObjectWidth / 2;
			}

			// snap by the top edge
			if (isInRange(guidelines[i].y, activeObjectTop - activeObjectHeight / 2))
			{
				snapYGuidelines = guidelines[i].y + activeObjectHeight / 2;
			}

			// snap by the bottom edge
			if (isInRange(guidelines[i].y, activeObjectTop + activeObjectHeight / 2))
			{
				snapYGuidelines = guidelines[i].y - activeObjectHeight / 2;
			}
		}

		if (snapXGuidelines && !snapYGuidelines)
		{
			snapYGuidelines = activeObjectTop;
		}
		else if (!snapXGuidelines && snapYGuidelines)
		{
			snapXGuidelines = activeObjectLeft;
		}

		if (snapXGuidelines && snapYGuidelines)
		{
			activeObject.setPositionByOrigin(new fabric.Point(snapXGuidelines, snapYGuidelines), 'center', 'center');
		}
	};

	/**
	 * LEFT MOUSE BUTTON
	 */
	const onMouseDownAligning = () =>
	{
		viewportTransform = canvas.viewportTransform;
		setTimeout(() => setSelectedGuideline(null), 0);
	};

	/**
	 * on each render tick remove OLD guidelines / aligning lines
	 */
	const onBeforeRenderAligning = () =>
	{
		canvas.clearContext(canvas.contextTop);
	};

	/**
	 * on after render tick draw NEW guidelines / aligning lines
	 * we redraw them because canvas.viewportTransform may change
	 */
	const onAfterRenderAligning = () =>
	{
		for (let i = verticalLines.length; i--; )
		{
			drawVerticalLine(verticalLines[i]);
		}

		for (let i = horizontalLines.length; i--; )
		{
			drawHorizontalLine(horizontalLines[i]);
		}

		horizontalLines.length = verticalLines.length = 0;
		renderGuideLines();
	};

	/**
	 * render guidelines on canvas
	 */
	const renderGuideLines = () =>
	{
		let zoom = canvas.getZoom();
		let topLeftCanvasX = fabric.util.invertTransform(canvas.viewportTransform)[4];
		let canvasWidth = canvas.width/zoom;
		let topLeftCanvasY = fabric.util.invertTransform(canvas.viewportTransform)[5];
		let canvasHeight = canvas.height/zoom;
		let visibleGuidelines = guidelinesVisibility ? guidelines : [];

		visibleGuidelines.forEach(guideline =>
		{
			if (guideline.direction === 'vertical')
			{
				drawLine(guideline.x, topLeftCanvasY + 50 + canvasHeight, guideline.x, topLeftCanvasY - 50);
			}
			else
			{
				drawLine(topLeftCanvasX - 50, guideline.y, topLeftCanvasX + 50 + canvasWidth, guideline.y);
			}
		});
	};

	/**
	 * RIGHT MOUSE BUTTON (when user opens context menu)
	 * to allow user create guidelines, we should store click coordinates
	 */
	const onMouseDownGuidelines = (e) =>
	{
		let pointer = canvas.getPointer(e.e);
		setClickX(pointer.x);
		setClickY(pointer.y);
		viewportTransform = canvas.viewportTransform;

		let selectedGuideline = guidelines.find(guideline => Math.abs(guideline.x - pointer.x) < 15 || Math.abs(guideline.y - pointer.y) < 15) || null;
		setSelectedGuideline(selectedGuideline);
	};

	const addGuideline = (e, direction = 'vertical') =>
	{
		setGuidelines([...guidelines, {direction, x : clickX, y : clickY}]);
	};

	const removeAllGuidelines = () =>
	{
		setGuidelines([]);
	};

	const removeSelectedGuideline = () =>
	{
		setGuidelines(guidelines.filter(guideline => !(guideline.x === selectedGuideline.x && guideline.y === selectedGuideline.y && guideline.direction === selectedGuideline.direction)));
		setSelectedGuideline(null);
	};

	const toggleGuidelinesVisibility = () =>
	{
		setGuidelinesVisibility(!guidelinesVisibility);
	};

	useEffect(() =>
	{
		if (canvas)
		{
			viewportTransform = canvas.viewportTransform;
			canvas.clearContext(canvas.contextTop);
			renderGuideLines();
		}
	}, [canvas, guidelines, zoom, guidelinesVisibility, selectedGuideline]);

	return {onObjectMoveAligning, onMouseDownAligning, onBeforeRenderAligning, onAfterRenderAligning,
		onMouseDownGuidelines, addGuideline, removeAllGuidelines, removeSelectedGuideline, toggleGuidelinesVisibility};
}
