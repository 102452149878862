import React, {useState, useEffect} from 'react';
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import {
    IconCopy,
    IconPremade,
    IconEmail,
    IconFill,
    IconInvert,
    IconBorderWidth, IconArrowBackwards
} from "../icons";
import {shallowEqual, useSelector} from "react-redux";
import {analyzeObject} from "../../logic/canvas";
import {isMacintosh} from "../../helper";
import CanvasContextMenuCornersRadius from "./CanvasContextMenuCornersRadius";
import CanvasContextMenuTextSize from "./CanvasContextMenuTextSize";
import CanvasContextMenuDimensions from "./CanvasContextMenuDimensions";
import {setTextEditingMode} from "../../ducks/main";
import CanvasContextMenuAligningOptions from "./CanvasContextMenuAligningOptions";

export default function CanvasContextMenu ({onUngroup, onGroup, onCreatePremade, canvasRightClicked, toggleFill,
                                               toggleColorInvert, setCornersRadius, setTextSize, onChangeStokeWidth,
                                               moveItemUp, moveItemDown, setDimensions, addGuideline, removeAllGuidelines,
                                               removeSelectedGuideline, toggleGuidelinesVisibility, t})
{
    const firstImpressionProcessed = useSelector(state => state.main.firstImpressionProcessed, shallowEqual);
    const user = useSelector(state => state.main.user, shallowEqual);

    const activeObject = useSelector(state => state.canvas.activeObject, shallowEqual);
    const lastActiveObjectChangeTime = useSelector(state => state.canvas.lastActiveObjectChangeTime, shallowEqual);
    const selectedGuideline = useSelector(state => state.guidelines.selectedGuideline, shallowEqual);

    const {isGroupSelected, groupCanBeCreated, premadeCanBeCreated} = analyzeObject(activeObject);

    const ctrl = isMacintosh() ? '⌘' : 'Ctrl';
    const alt = isMacintosh() ? '⌥' : 'Alt';

    useEffect(() =>
    {
        //catch menu close
        if (!canvasRightClicked && !activeObject)
        {
            setTextEditingMode(false);
        }
    }, [canvasRightClicked, activeObject]);

    return (
        <React.Fragment>

            <ContextMenuTrigger id="canvas-context-menu" holdToDisplay={-1}>
                <canvas className={firstImpressionProcessed ? "" : "animate"} id="canvas" width={window.innerWidth - 10} height={window.innerHeight - 10}></canvas>
            </ContextMenuTrigger>

            {
                (canvasRightClicked || activeObject) &&
                <ContextMenu id="canvas-context-menu" className="context-menu" hideOnLeave={false}>
                    {
                        !canvasRightClicked &&
                        <React.Fragment>
                            <MenuItem onClick={isGroupSelected ? onUngroup : onGroup} className={`context-menu-item ${(groupCanBeCreated || isGroupSelected) ? '' : 'disabled'}`}>
                                <div>
                                    <IconCopy width={20} height={20}/>
                                    <span>{isGroupSelected ? t("contextMenu.ungroup") : t("contextMenu.group")}</span>
                                </div>
                                <span className="text-secondary">{ctrl}+{alt}+{isGroupSelected ? 'Shift+' : ''}G</span>
                            </MenuItem>
                            <MenuItem onClick={onCreatePremade} className={`context-menu-item ${(premadeCanBeCreated && (true || user)) ? '' : 'disabled'}`}>
                                <div>
                                    <IconPremade width={20} height={20}/>
                                    <span>{t("contextMenu.createPremade")}</span>
                                </div>
                                <span className="text-secondary">{ctrl}+{alt}+P</span>
                            </MenuItem>
                            {
                                activeObject && !isGroupSelected && !groupCanBeCreated &&
                                <React.Fragment>
                                    {
                                        activeObject.type !== 'textbox' &&
                                        <MenuItem onClick={() => toggleFill()} className={`context-menu-item`}>
                                            <div>
                                                <IconFill width={20} height={20}/>
                                                <span>{activeObject.filled ? t("contextMenu.unfillTheShape") : t("contextMenu.fillTheShape") }</span>
                                            </div>
                                            <span className="text-secondary">{ctrl}+{alt}+{activeObject.filled ? 'Shift+' : ''}F</span>
                                        </MenuItem>
                                    }

                                    <MenuItem onClick={() => toggleColorInvert()} className={`context-menu-item`}>
                                        <div>
                                            <IconInvert width={20} height={20}/>
                                            <span>{t("contextMenu.invertColor")}</span>
                                        </div>
                                        <span className="text-secondary">{ctrl}+{alt}+{activeObject.colorInverted ? 'Shift+' : ''}C</span>
                                    </MenuItem>

                                    {
                                        activeObject.type === 'rect' &&
                                        <CanvasContextMenuCornersRadius setCornersRadius={setCornersRadius}/>
                                    }

                                    {
                                        activeObject.type === 'textbox' &&
                                        <CanvasContextMenuTextSize setTextSize={setTextSize}/>
                                    }

                                    {
                                        activeObject.type !== 'textbox' &&
                                        <MenuItem className={`context-menu-item line-width`}>
                                            <div>
                                                <IconBorderWidth width={20} height={20}/>
                                                <span>{t("contextMenu.lineWidth")}</span>
                                            </div>
                                            <div>
                                                <button onClick={(e) => onChangeStokeWidth(1, e.target)} className={activeObject.strokeWidth === 1 ? 'selected' : ''} type="button">1</button>
                                                <button onClick={(e) => onChangeStokeWidth(3, e.target)} className={activeObject.strokeWidth === 3 ? 'selected' : ''} type="button">3</button>
                                                <button onClick={(e) => onChangeStokeWidth(5, e.target)} className={activeObject.strokeWidth === 5 ? 'selected' : ''} type="button">5</button>
                                            </div>
                                        </MenuItem>
                                    }

                                    <MenuItem onClick={() => moveItemUp()} className={`context-menu-item`}>
                                        <div>
                                            <IconArrowBackwards style={{transform: `rotate(-90deg)`}} width={20} height={20}/>
                                            <span>{t("contextMenu.bringForward")}</span>
                                        </div>
                                        <span className="text-secondary">{ctrl}+↑</span>
                                    </MenuItem>

                                    <MenuItem onClick={() => moveItemDown()} className={`context-menu-item`}>
                                        <div>
                                            <IconArrowBackwards style={{transform: `rotate(90deg)`}} width={20} height={20}/>
                                            <span>{t("contextMenu.sendBackward")}</span>
                                        </div>
                                        <span className="text-secondary">{ctrl}+↓</span>
                                    </MenuItem>

                                </React.Fragment>
                            }

                            {
                                (activeObject.type !== 'textbox') &&
                                <CanvasContextMenuDimensions setDimensions={setDimensions}/>
                            }

                        </React.Fragment>
                    }
                    {
                        canvasRightClicked &&
                        <React.Fragment>

                            <CanvasContextMenuAligningOptions addGuideline={addGuideline}
                                                              removeAllGuidelines={removeAllGuidelines}
                                                              removeSelectedGuideline={removeSelectedGuideline}
                                                              toggleGuidelinesVisibility={toggleGuidelinesVisibility}
                                                              selectedGuideline={selectedGuideline}
                                                              ctrl={ctrl}
                                                              alt={alt}/>

                            {
                                !selectedGuideline &&
                                <MenuItem className={`context-menu-item`}>
                                    <a href="mailto:amitait@gmail.com" style={{textDecoration: 'none', color: '#fff'}}>
                                        <IconEmail width={20} height={20}/>
                                        <span>{t("contextMenu.sendFeedback")}</span>
                                    </a>
                                </MenuItem>
                            }
                        </React.Fragment>
                    }
                </ContextMenu>
            }
        </React.Fragment>
    );
}
