import React, {useState} from 'react';
import {
	IconAddHorizontalGuideline,
	IconAddVerticalGuideline,
	IconHideGuidelines,
	IconRemoveGuideline,
} from "../icons";
import {MenuItem} from "react-contextmenu";
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export default function CanvasContextMenuAligningOptions ({ctrl, alt, addGuideline, removeAllGuidelines, removeSelectedGuideline, toggleGuidelinesVisibility, selectedGuideline})
{
	const guidelines = useSelector(state => state.guidelines.guidelines, shallowEqual);
	const guidelinesVisibility = useSelector(state => state.guidelines.guidelinesVisibility, shallowEqual);
	const {t} = useTranslation();

	if (selectedGuideline !== null)
	{
		return (
			<React.Fragment>
				<MenuItem onClick={removeSelectedGuideline} className={`context-menu-item`}>
					<div>
						<IconRemoveGuideline width={20} height={20}/>
						<span>{t("contextMenu.removeGuideline")}</span>
					</div>
					<span className="text-secondary"></span>
				</MenuItem>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<MenuItem onClick={(e) => addGuideline(e, 'vertical')} className={`context-menu-item`}>
				<div>
					<IconAddVerticalGuideline width={20} height={20}/>
					<span>{t("contextMenu.addVerticalGuideline")}</span>
				</div>
				<span className="text-secondary"></span>
			</MenuItem>
			<MenuItem onClick={(e) => addGuideline(e, 'horizontal')} className={`context-menu-item`}>
				<div>
					<IconAddHorizontalGuideline width={20} height={20}/>
					<span>{t("contextMenu.addHorizontalGuideline")}</span>
				</div>
				<span className="text-secondary"></span>
			</MenuItem>
			{
				guidelines.length !== 0 &&
				<React.Fragment>
					<MenuItem onClick={toggleGuidelinesVisibility} className={`context-menu-item`}>
						<div>
							<IconHideGuidelines width={20} height={20}/>
							<span>{guidelinesVisibility ? t('contextMenu.hideAllGuidelines') : t('contextMenu.showAllGuidelines')}</span>
						</div>
						<span className="text-secondary">{ctrl}+{alt}+H</span>
					</MenuItem>
					<MenuItem onClick={removeAllGuidelines} className={`context-menu-item`}>
						<div>
							<IconRemoveGuideline width={20} height={20}/>
							<span>{t("contextMenu.removeAllGuidelines")}</span>
						</div>
						<span className="text-secondary"></span>
					</MenuItem>
				</React.Fragment>
			}
		</React.Fragment>
	);
}
