import {createAction, createReducer} from "redux-act";

export const setShareId = createAction('set share id', id => id);
export const setUser = createAction('save user data', user => user);
export const setFirstImpressionProcessed = createAction('set first impression processed', firstImpressionProcessed => firstImpressionProcessed);
export const setPremadesOpenedOnce = createAction('set premades opened once', premadesOpenedOnce => premadesOpenedOnce);
export const setTextEditingMode = createAction('set text editing mode', textEditingMode => textEditingMode);
export const setShareBoxOpened = createAction('set share box opened', shareBoxOpened => shareBoxOpened);

const initialState = {
    shareId: null,
    user: null,
    premadesOpenedOnce : false,
    firstImpressionProcessed: false,
    textEditingMode: false,
    shareBoxOpened : false
};

const main = createReducer({
    [setShareId]: (state, payload) => ({...state, shareId: payload}),
    [setUser]: (state, payload) => ({...state, user: payload}),
    [setPremadesOpenedOnce]: (state, payload) => ({...state, premadesOpenedOnce: payload}),
    [setFirstImpressionProcessed]: (state, payload) => ({...state, firstImpressionProcessed: payload}),
    [setTextEditingMode]: (state, payload) => ({...state, textEditingMode: payload}),
    [setShareBoxOpened]: (state, payload) => ({...state, shareBoxOpened: payload}),
}, initialState);

export default main;
