import React, {useEffect, useRef, useState} from 'react';
import {shallowEqual, useSelector} from "react-redux";

import {getKeyListener, useEventListener} from "../logic/share";
import Logo from "../components/Logo";
import {IconCircle, IconTwoArrows, IconPremade, IconArrow, IconCursor, IconLine, IconRect, IconText, IconTriangle} from "../components/icons";
import {useTheme} from "../theme-manager";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";
import {countPremades} from "../logic/premades";
import {setMode} from "../ducks/canvas";
import {setPremadesModalOpened} from "../ducks/premades";
import {useDrawLogic} from "../logic/draw";
import * as firebase from "firebase";
import {useTranslation} from "react-i18next";

const shapes = ['rect', 'triangle', 'circle', 'line', 'arrow', 'text'];

export default function Actions ({})
{
    const firstImpressionProcessed = useSelector(state => state.main.firstImpressionProcessed, shallowEqual);
    const textEditingMode = useSelector(state => state.main.textEditingMode, shallowEqual);
    const shareId = useSelector(state => state.main.shareId, shallowEqual);

    const mode = useSelector(state => state.canvas.mode, shallowEqual);
    const canvas = useSelector(state => state.canvas.canvas, shallowEqual);

    const premadeAdded = useSelector(state => state.premades.premadeAdded, shallowEqual);
    const premadesModalOpened = useSelector(state => state.premades.premadesModalOpened, shallowEqual);
    const addPremadeModalOpened = useSelector(state => state.premades.addPremadeModalOpened, shallowEqual);

    const premadeButtonEl = useRef();
    const [premadesCount, setPremadesCount] = useState(0);
    const {onDefaultShapeDraw, onMouseSelect} = useDrawLogic();

    const {t} = useTranslation();

    useEventListener('keydown', getKeyListener([27], onMouseSelect));
    useEventListener('keydown', getKeyListener([83], () => !textEditingMode && !addPremadeModalOpened && selectShape('rect'), false, false, false));
    useEventListener('keydown', getKeyListener([84], () => !textEditingMode && !addPremadeModalOpened && selectShape('triangle'), false, false, false));
    useEventListener('keydown', getKeyListener([67], () => !textEditingMode && !addPremadeModalOpened && selectShape('circle'), false, false, false));
    useEventListener('keydown', getKeyListener([76], () => !textEditingMode && !addPremadeModalOpened && selectShape('line'), false, false, false));
    useEventListener('keydown', getKeyListener([65], () => !textEditingMode && !addPremadeModalOpened && selectShape('arrow'), false, false, false));

    useEffect(() =>
    {
        if (premadeAdded)
        {
            showTooltip();
        }
    }, [premadeAdded]);

    useEffect(() =>
    {
        if (!firstImpressionProcessed && !shareId)
        {
            getPremadesCount();
        }
    }, [firstImpressionProcessed, shareId]);

    async function getPremadesCount ()
    {
        let count = await countPremades();
        count = Math.floor(count / 10) * 10;
        setPremadesCount(count);
        showTooltip(1000);
    }

    function showTooltip (delay = 0)
    {
        setTimeout(() => ReactTooltip.show(premadeButtonEl.current), delay);
    }

    function selectShape (shape)
    {
        setMode(shape);
        canvas.selection = false;
    }

    function onPremadesOpen ()
    {
        firebase.analytics().logEvent("tools_menu_premades");
        setPremadesModalOpened(true);
    }

    function renderTooltip ()
    {
        if (!firstImpressionProcessed)
        {
            return ReactDOMServer.renderToString(
                <div className="share-tooltip">
                    <IconTwoArrows style={{transform : 'rotate(180deg)', marginRight: 5}}/>
                    <span>{t("firstImpressionTooltip").replace(/{premadesCount}/, premadesCount.toString())}</span>
                </div>
            );
        }
        else if (premadeAdded)
        {
            return ReactDOMServer.renderToString(
                <div className="share-tooltip">
                    <IconTwoArrows style={{transform : 'rotate(180deg)', marginRight: 5}}/>
                    <span>{t("addPremadeDialog.premadeAddedTooltip")}</span>
                </div>
            );
        }
        else return null;
    }

    function renderButtonIcon  (shape)
    {
        switch (shape) {
            case "rect":
                return <IconRect width={14} height={14} style={{marginTop: 4}}/>;
            case "triangle":
                return <IconTriangle width={20} height={20} style={{marginTop: 2}}/>;
            case "circle":
                return <IconCircle style={{marginTop: 2}}/>;
            case "line":
                return <IconLine style={{marginTop: 2}} width={16} height={16}/>;
            case "text":
                return <IconText style={{marginTop: 3}} width={16} height={16}/>;
            case "arrow":
                return <IconArrow style={{marginTop: 2}}/>;
        }
    }
    const { theme } = useTheme();

    return (
        <div className="actions-container" data-aos={firstImpressionProcessed ? "none" : "fade"} data-aos-offset="0" data-aos-delay="1100" data-aos-duration="300">

            <Logo/>

            <button onClick={onMouseSelect} className={`${mode === 'mouse' ? 'selected' : ''} round-button`}>
                <IconCursor/>
            </button>

            <button ref={premadeButtonEl} data-tip={renderTooltip()} onClick={onPremadesOpen}
                    className={`${premadesModalOpened ? 'selected' : ''} hover-opacity round-button`}>
                <IconPremade width={20} height={20} style={{marginTop: 3}}/>
            </button>

            {
                shapes.map(shape => (
                    <button key={shape}
                            onClick={() => selectShape(shape)}
                            onDoubleClick={() => onDefaultShapeDraw(shape)}
                            className={`${mode === shape ? 'selected' : ''} hover-opacity round-button`} style={{color: theme.primaryColor}}>
                        {/*<IconRect style={{marginLeft: -3, marginTop: 2}} size={30}/>*/}
                        {renderButtonIcon(shape)}
                    </button>
                ))
            }</div>
    )
}
