import * as React from "react";

function SvgIconFolder(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={24}
      height={24}
      {...props}
    >
      <linearGradient
        id="IconFolder_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={16.5}
        x2={49}
        y2={85.5}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M84 27.5H47.3l-9.8-10.1c-.6-.6-1.3-.9-2.2-.9H14c-2.8 0-5 2.2-5 5v59c0 2.8 2.2 5 5 5h70c2.8 0 5-2.2 5-5v-48c0-2.8-2.2-5-5-5zm-1 52H15v-57h19l9.8 10.1c.6.6 1.3.9 2.2.9h37v46z"
        fill="url(#IconFolder_svg__a)"
      />
    </svg>
  );
}

export default SvgIconFolder;
