import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import {loadPremadesFromDb} from "../logic/premades";
import {setPremadesOpenedOnce, setTextEditingMode, setUser} from "../ducks/main";
import {shallowEqual, useSelector} from "react-redux";
import * as firebase from "firebase";
import InfiniteScroll from 'react-infinite-scroller';
import ReactTooltip from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import IconTwoArrows from "../components/icons/IconTwoArrows";
import {setPremadesModalOpened} from "../ducks/premades";
import {PremadeItem, Loader} from "../components";
import {useTranslation} from "react-i18next";
import AuthButton from "../components/AuthButton";
import {icons} from "../svgIcons";
import CryptoJS from "crypto-js/crypto-js";
import {KEY} from "../logic/share";

Modal.defaultStyles.overlay.backgroundColor = '#00000060';
Modal.setAppElement('#root');

const customStyles = {
	content: {
		top: '110px',
		left: '110px',
		width: '600px',
		padding: 0,
		bottom: 'unset',
		maxHeight: 'calc(100% - 140px)',
		borderRadius : 10
	},
	overlay: {
		zIndex: 10
	}
};

export default function PremadesModal ({})
{
	const user = useSelector(state => state.main.user, shallowEqual);
	const premadesOpenedOnce = useSelector(state => state.main.premadesOpenedOnce, shallowEqual);

	const premadesModalOpened = useSelector(state => state.premades.premadesModalOpened, shallowEqual);

	const [initialLoaded, setInitialLoaded] = useState(false);
	const [moreExists, setMoreExists] = useState(true);
	const [loading, setLoading] = useState(false);
	const [premades, setPremades] = useState([]);
	const [mode, setMode] = useState('ALL');
	const [search, setSearch] = useState('');
	const [lastDoc, setLastDoc] = useState(null);

	const searchInputEl = useRef();
	const contentEl = useRef();

	const {t} = useTranslation();

	async function loadPremades (force = false, currentPremades = [])
	{
		if (loading)
		{
			return;
		}

		setLoading(true);

		let newPremades = [],
			updatedPremades = [],
			premadeIdFromSearch;

		try
		{
			premadeIdFromSearch = CryptoJS.AES.decrypt(search, KEY).toString(CryptoJS.enc.Utf8)
		}
		catch (e)
		{
			console.warn(e);
		}

		if (mode === "ICONS")
		{
			newPremades = search !== "" ? icons : icons.slice(currentPremades.length, currentPremades.length + 10);
		}
		else if (!(mode !== 'ALL' && !user))
		{
			let startAfter = force ? null : lastDoc;

			let [fetchedPremades, newLastDoc] = await loadPremadesFromDb(premadeIdFromSearch ? [premadeIdFromSearch] : [], mode === 'MY' ? user : null, mode === 'COMPANY' ? user.company : null, null, startAfter, search !== "" ? 0 : 10);
			newPremades = fetchedPremades;
			setLastDoc(newLastDoc);
		}

		if (!force)
		{
			newPremades = newPremades.filter(premade => currentPremades.find(existingPremade => existingPremade.id === premade.id) === undefined);
			updatedPremades = [...currentPremades, ...newPremades];
		}
		else
		{
			updatedPremades = newPremades;
		}

		if (search && !premadeIdFromSearch)
		{
			updatedPremades = updatedPremades.filter(premade => premade.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
		}

		setPremades(updatedPremades);

		if (newPremades.length === 0)
		{
			setMoreExists(false);
		}
		setLoading(false);
		setInitialLoaded(true);
	}

	useEffect(() =>
	{
		if (initialLoaded)
		{
			setMoreExists(true);
			setInitialLoaded(false);
			setPremades([]);
			setLastDoc(null);
			loadPremades(true, []);
			searchInputEl.current && searchInputEl.current.focus();
		}
	}, [mode, search]);

	useEffect(() =>
	{
		if (premadesModalOpened)
		{
			loadPremades(true, []);
		}
		else
		{
			ReactTooltip.hide(contentEl.current);
		}
	}, [premadesModalOpened]);

	useEffect(() =>
	{
		if (premadesModalOpened && !premadesOpenedOnce && contentEl)
		{
			ReactTooltip.rebuild();
			setPremadesOpenedOnce(true);
			setTimeout(() => ReactTooltip.show(contentEl.current), 500);
			//setTimeout(() => ReactTooltip.hide(contentEl.current), 3000);
		}
	}, [premadesModalOpened, premadesOpenedOnce]);

	function onClose ()
	{
		setPremadesModalOpened(false);
	}

	function getBodyHeight ()
	{
		if (loading && !initialLoaded)
		{
			return Math.min(window.innerHeight - 320, 200);
		}
		else if (mode === 'MY' && !user || premades.length === 0)
		{
			return Math.min(window.innerHeight - 320, 200);
		}
		else
		{
			return window.innerHeight - 320;
		}
	}

	function renderTooltip ()
	{
		return ReactDOMServer.renderToString(
			<div className="share-tooltip">
				<IconTwoArrows style={{transform : 'rotate(180deg)', marginRight: 5}}/>
				<span>
					{t("premadesDialog.premadeAddTooltip")}
				</span>
			</div>
		);
	}

	function selectMode (mode)
	{
		setMode(mode);
		firebase.analytics().logEvent(`premades_menu_${mode}_area`);
	}

	return (
		<Modal
			isOpen={premadesModalOpened}
			style={customStyles}
			onAfterOpen={() => false}
			onRequestClose={onClose}
		>

			<div className="modal">
				<div ref={contentEl} data-tip={renderTooltip()} data-place="right" className="tooltip-anchor"></div>
				<div className="modal-header">
					<div className="title">{t("premadesDialog.title")}</div>
					<div className="toggles mt-2">
							<span onClick={() => selectMode('ALL')}
								  className={`${mode === 'ALL' ? 'selected' : ''} hover-opacity`}>{t("premadesDialog.all")}</span>
						<span className="delimiter mr-2 ml-2">|</span>
						<span onClick={() => selectMode('MY')}
							  className={`${mode === 'MY' ? 'selected' : ''} hover-opacity`}>{t("premadesDialog.my")}</span>
						{
							user && user.company &&
								<React.Fragment>
									<span className="delimiter mr-2 ml-2">|</span>
									<span onClick={() => selectMode('COMPANY')} className={`${mode === 'COMPANY' ? 'selected' : ''} hover-opacity`}>{user.company}</span>
								</React.Fragment>
						}
						<span className="delimiter mr-2 ml-2">|</span>
						<span onClick={() => selectMode('ICONS')}
							  className={`${mode === 'ICONS' ? 'selected' : ''} hover-opacity`}>{t("premadesDialog.icons")}</span>
					</div>
				</div>

				{
					!(mode === 'MY' && !user) &&
					<div className="mb-3">
						<input value={search}
							   ref={searchInputEl}
							   autoFocus={true}
							   onChange={e => setSearch(e.target.value)}
							   onFocus={() => setTextEditingMode(true)}
							   onBlur={() => setTextEditingMode(false)}
							   style={{paddingRight: 30, fontSize: 14}}
							   className="form-control w-100"/>
					</div>
				}

				<div className="modal-body" style={{height: getBodyHeight(), overflow: 'auto', paddingRight: 30}}>

					{
						loading && !initialLoaded && <Loader/>
					}

					{
						(premades.length !== 0 && mode === 'MY' && user || mode === 'ALL' || mode === 'ICONS' || mode === 'COMPANY' && user && user.company) &&
						<InfiniteScroll pageStart={0} loadMore={() => loadPremades(false, premades)} hasMore={moreExists} loader={null}
										useWindow={false} initialLoad={false}>
							<div className="d-flex flex-wrap justify-content-between">
								{
									premades.map(premade => (<PremadeItem key={premade.id} premade={premade}/>))
								}
							</div>
						</InfiniteScroll>
					}

					{
						!loading && premades.length === 0 && !(mode === 'MY' && !user) &&
						<p>{t("premadesDialog.empty")}.</p>
					}

					{
						!loading && (mode === 'MY') && (premades.length === 0 || !user) &&
						<React.Fragment>

							{
								premades.length === 0 &&
								<p>{t("premadesDialog.myDesc")}.</p>
							}

							{
								!user &&
								<React.Fragment>
									<p>{t("premadesDialog.signIn")}.</p>

									<AuthButton/>

								</React.Fragment>
							}

						</React.Fragment>
					}

				</div>

			</div>

		</Modal>
	);
}
