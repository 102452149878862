import {persistReducer, persistStore} from "redux-persist";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import main, {
    setShareId,
    setUser,
    setFirstImpressionProcessed,
    setPremadesOpenedOnce,
    setTextEditingMode,
    setShareBoxOpened,
} from "./ducks/main";
import canvas, {
    setMode,
    setChangesExists,
    setSavedLocalData,
    setCanvas,
    setTemplate, setActiveObject, setLastActiveObjectChangeTime,
    setMemorizedObject, setCanvasZoom, setShowZoomIndicator
} from "./ducks/canvas";
import premades, {
    setAddPremadeModalOpened,
    setPremadeAdded,
    setPremadesModalOpened,
    setAddedPremadesIds, setCreatedPremadeData, setOpenedPremadeData
} from "./ducks/premades";

import guidelines, {
    setGuidelines,
    setGuidelinesVisibility, setSelectedGuideline
} from "./ducks/guidelines";

import storage from 'redux-persist/lib/storage'
import ideas, {
    setEditIdeaModalOpened,
    setIdeasModalOpened,
    setEditableIdea,
    setIdeasUpdated,
    setNotLoggedForNewIdeaModalOpened,
    setForceSaveIdea
} from "./ducks/ideas";

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
export let persistorLink;

export function configureStore ()
{
    const middlewares = [];
    if (false && development)
    {
        const {logger} = require(`redux-logger`);
        middlewares.push(logger);
    }

    const withStorage = true;

    const rootReducer = combineReducers({
        main : withStorage ? persistReducer({key: "main", storage, whitelist: ['user', 'firstImpressionProcessed', 'premadesOpenedOnce']}, main) : main,
        canvas : withStorage ? persistReducer({key: "canvas", storage, whitelist: ['localData']}, canvas) : canvas,
        premades : withStorage ? persistReducer({key: "premades", storage, whitelist: []}, premades) : premades,
        ideas : withStorage ? persistReducer({key: "ideas", storage, whitelist: []}, ideas) : ideas,
        guidelines : withStorage ? persistReducer({key: "guidelines", storage, whitelist: ['guidelines', 'guidelinesVisibility']}, guidelines) : guidelines,
    });
    const enhancer = compose(applyMiddleware(...middlewares));
    const store = createStore(rootReducer, enhancer);
    const persistor = persistStore(store);
    persistorLink = persistor;

    assignActionsCreators(store);

    return {store, persistor};
}

function assignActionsCreators (store) {
    setMode.assignTo(store);
    setTemplate.assignTo(store);
    setCanvas.assignTo(store);
    setShareId.assignTo(store);
    setSavedLocalData.assignTo(store);
    setPremadesModalOpened.assignTo(store);
	setAddedPremadesIds.assignTo(store);
    setUser.assignTo(store);
    setCanvasZoom.assignTo(store);
    setShowZoomIndicator.assignTo(store);
    setGuidelines.assignTo(store);

    setPremadeAdded.assignTo(store);
    setChangesExists.assignTo(store);
    setActiveObject.assignTo(store);
    setLastActiveObjectChangeTime.assignTo(store);
    setMemorizedObject.assignTo(store);

    setFirstImpressionProcessed.assignTo(store);
    setPremadesOpenedOnce.assignTo(store);
    setTextEditingMode.assignTo(store);
    setAddPremadeModalOpened.assignTo(store);
    setCreatedPremadeData.assignTo(store);
    setOpenedPremadeData.assignTo(store);
    setShareBoxOpened.assignTo(store);

    setIdeasModalOpened.assignTo(store);
    setEditIdeaModalOpened.assignTo(store);
    setEditableIdea.assignTo(store);
    setIdeasUpdated.assignTo(store);
    setNotLoggedForNewIdeaModalOpened.assignTo(store);
    setForceSaveIdea.assignTo(store);

    setGuidelines.assignTo(store);
    setGuidelinesVisibility.assignTo(store);
    setSelectedGuideline.assignTo(store);
}
