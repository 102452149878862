import {shallowEqual, useSelector} from "react-redux";
import {useCallback} from "react";
import {setMemorizedObject} from "../ducks/canvas";
import {customProps} from "./canvas";

export function useCopyPasteLogic ()
{
	const textEditingMode = useSelector(state => state.main.textEditingMode, shallowEqual);

	const canvas = useSelector(state => state.canvas.canvas, shallowEqual);
	const activeObject = useSelector(state => state.canvas.activeObject, shallowEqual);
	const memorizedObject = useSelector(state => state.canvas.memorizedObject, shallowEqual);

	const onCopy = useCallback((e, callback) =>
	{
		if (textEditingMode)
		{
			return;
		}

		if (!activeObject)
		{
			return;
		}

		activeObject.clone(object =>
		{
			setMemorizedObject(object);

			console.log(object.filled);
			if (callback !== undefined)
			{
				callback();
			}
		}, customProps);

	}, [canvas, activeObject, textEditingMode]);

	const onCut = useCallback((e) =>
	{
		if (textEditingMode)
		{
			return;
		}

		onCopy(null, () =>
		{
			canvas.remove(activeObject);
			canvas.discardActiveObject();
			canvas.renderAll();
		});
	}, [canvas, activeObject, textEditingMode]);

	const onPaste = useCallback((e) =>
	{
		if (textEditingMode)
		{
			return;
		}

		if (!memorizedObject)
		{
			return;
		}

		memorizedObject.clone(clonedObject =>
		{
			canvas.discardActiveObject();
			clonedObject.set({
				left: clonedObject.left + 10,
				top: clonedObject.top + 10,
				evented: true,
			});
			if (clonedObject.type === 'activeSelection') {
				// active selection needs a reference to the canvas.
				clonedObject.canvas = canvas;
				clonedObject.forEachObject(function(obj) {
					canvas.add(obj);
				});
				// this should solve the unselectability
				clonedObject.setCoords();
			} else {
				canvas.add(clonedObject);
			}
			memorizedObject.top += 10;
			memorizedObject.left += 10;
			canvas.setActiveObject(clonedObject);
			canvas.requestRenderAll();
		}, customProps);
	}, [canvas, memorizedObject, textEditingMode]);

	return {onCopy, onCut, onPaste};
}
