import React from 'react';
import {useTranslation} from "react-i18next";
import IconFolder from "../components/icons/IconFolder";
import {shallowEqual, useSelector} from "react-redux";
import {setIdeasModalOpened, setNotLoggedForNewIdeaModalOpened} from "../ducks/ideas";

export default function IdeasButton ({})
{

	const user = useSelector(state => state.main.user, shallowEqual);
	const ideasModalOpened = useSelector(state => state.ideas.ideasModalOpened, shallowEqual);
	const changesExists = useSelector(state => state.canvas.changesExists, shallowEqual);

	const {t} = useTranslation();

	function openIdeas ()
	{
		if (!user)
		{
			setNotLoggedForNewIdeaModalOpened(true);
		}
		else
		{
			setIdeasModalOpened(!ideasModalOpened);
		}
	}

	return (
	    <button onClick={openIdeas} type="button" style={{bottom: changesExists ? '100px' : '30px'}} className={`ideas-button round-button ${ideasModalOpened ? 'selected' : ''}`}>
			<IconFolder style={{transform : 'scale(0.8)'}}/>
		</button>
    )
}
