import * as React from "react";

function SvgIconAddIdea(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <linearGradient
        id="IconAddIdea_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={29.594}
        y1={405.32}
        x2={29.594}
        y2={336.32}
        gradientTransform="matrix(5.2245 0 0 -5.2245 101.388 2193.347)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M438.9 133.2H247.1l-51.2-52.8c-3.1-3.1-6.8-4.7-11.5-4.7H73.1C58.5 75.8 47 87.2 47 101.9v308.2c0 14.6 11.5 26.1 26.1 26.1h365.7c14.6 0 26.1-11.5 26.1-26.1V159.3c.1-14.6-11.4-26.1-26-26.1zm-5.3 271.7H78.4V107.1h99.3l51.2 52.8c3.1 3.1 6.8 4.7 11.5 4.7h193.3v240.3zM325.7 271.7h-57.4v-57.4c0-7-5.3-12.3-12.3-12.3s-12.3 5.3-12.3 12.3v57.4h-57.4c-7 0-12.3 5.3-12.3 12.3s5.3 12.3 12.3 12.3h57.4v57.4c0 7 5.3 12.3 12.3 12.3s12.3-5.3 12.3-12.3v-57.4h57.4c7 0 12.3-5.3 12.3-12.3s-5.4-12.3-12.3-12.3z"
        fill="url(#IconAddIdea_svg__a)"
      />
    </svg>
  );
}

export default SvgIconAddIdea;
