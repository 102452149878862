import * as React from "react";

function SvgIconEdit(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={24}
      height={24}
      {...props}
    >
      <linearGradient
        id="IconEdit_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={13.4}
        x2={49}
        y2={88.6}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M85.7 29.8L70.2 14.3c-1.2-1.2-3.1-1.2-4.2 0L17.2 63c-.4.4-.7.9-.8 1.4l-4.9 20.5c-.2 1 .1 2.1.8 2.8.6.6 1.3.9 2.1.9.2 0 .5 0 .7-.1l20.5-4.9c.5-.1 1-.4 1.4-.8L85.7 34c1.2-1.2 1.2-3.1 0-4.2zM23.4 65.3l33.2-33.2 3.5 3.5L27 68.8l-3.6-3.5zm-2.5 6l7.8 7.8-10.3 2.5 2.5-10.3zm13.8 5.3L31.1 73l33.2-33.2 3.5 3.5-33.1 33.3zm37.5-37.5L60.9 27.8l7.2-7.2 11.3 11.3-7.2 7.2z"
        fill="url(#IconEdit_svg__a)"
      />
    </svg>
  );
}

export default SvgIconEdit;
