import {shallowEqual, useSelector} from "react-redux";
import {useCallback, useState} from "react";
import {gridSize} from "./grid";

import {fabric} from "fabric";
import {setMode, setTemplate} from "../ducks/canvas";

import {initRect, initText, initTriangle, initLine, initCircle, initArrowLine} from "../logic/shapes";
import * as firebase from "firebase";

export function useDrawLogic ()
{
    let templateRendered = false;

    const [origX, setOrigX] = useState(0);
    const [origY, setOrigY] = useState(0);

    const mode = useSelector(state => state.canvas.mode, shallowEqual);
    const canvas = useSelector(state => state.canvas.canvas, shallowEqual);
    const template = useSelector(state => state.canvas.template, shallowEqual);

    const onMouseDown = useCallback((e) =>
    {
        if (!canvas || mode === 'mouse' || e.target)
        {
            return;
        }

        let pointer = canvas.getPointer(e.e),
            snappedX = snapToGrid(pointer.x),
            snappedY = snapToGrid(pointer.y),
            shapeFigure,
            addition = 0;

        if (mode === 'line' || mode === 'arrow')
        {
            addition = -1.5;
        }

        snappedX += addition;
        snappedY += addition;

        setOrigX(snappedX);
        setOrigY(snappedY);

        switch (mode) {
            case "rect":
                shapeFigure = initRect(snappedX, snappedY);
                break;
            case "triangle":
                shapeFigure = initTriangle(snappedX, snappedY);
                break;
            case "circle":
                shapeFigure = initCircle(snappedX, snappedY);
                break;
            case "line":
                shapeFigure = initLine(snappedX, snappedY);
                break;
            case "arrow":
                shapeFigure = initArrowLine(snappedX, snappedY);
                break;
            case "text":
                shapeFigure = initText(snappedX, snappedY);
                break;
        }

        //canvas.add(shapeFigure);
        canvas.drawMode = true;
        templateRendered = false;
        setTemplate(shapeFigure);

    }, [canvas, mode]);

    const onMouseMove = useCallback((e) => {

        if (!canvas || !template || !template.setCoords || mode === 'mouse')
        {
            return;
        }

        if (!templateRendered)
        {
            templateRendered = true;
            canvas.add(template);
        }

        let pointer = canvas.getPointer(e.e),
            updatedParams = {},
            propsNames = {'circle' : 'radius', 'text' : 'fontSize'},
            propName;

        if (mode === 'line' || mode === 'arrow')
        {
            updatedParams.x2 = snapToGrid(pointer.x) - 1.5;
            updatedParams.y2 = snapToGrid(pointer.y) - 1.5;
            if (mode === 'arrow')
            {
                template.refreshTriangle(updatedParams.x2 + 1.5, updatedParams.y2 + 1.5);
            }
        }
        else
        {
            propName = propsNames[mode] || 'width';
            if (origX > pointer.x || origX < 0 && origX > pointer.x)
            {
                updatedParams.left = snapToGrid(pointer.x);
                updatedParams[propName] = snapToGrid(Math.abs(origX - pointer.x));
            }
            else
            {
                updatedParams[propName] = snapToGrid(Math.abs(origX - pointer.x));
            }

            propName = propsNames[mode] || 'height';
            if (propName === 'height')
            {
                if (origY > pointer.y || origY < 0 && origY > pointer.y)
                {
                    updatedParams.top = snapToGrid(pointer.y);
                    updatedParams[propName] = snapToGrid(Math.abs(origY - pointer.y));
                }
                else
                {
                    updatedParams[propName] = snapToGrid(Math.abs(origY - pointer.y));
                }
            }

            if (mode === 'circle')
            {
                updatedParams.radius = updatedParams.radius / 2;
            }
        }

        //console.log(updatedParams);
        template.set(updatedParams);
        canvas.renderAll();
        template.selectable = true;
        template.moved = true;

    }, [canvas, template, templateRendered]);

    const onMouseUp = useCallback((e) => {

        if (!canvas || mode === 'mouse' || !template)
        {
            return;
        }

        if (mode !== 'arrow' && mode !== 'line' && mode !== 'text')
        {
            if (template.width < 20)
            {
                template.set({ width: 20 });
            }

            if (template.height < 20)
            {
                template.set({ height: 20 });
            }
        }


        if (template.moved)
        {
            canvas.setActiveObject(template);
            template.setCoords();
            firebase.analytics().logEvent(`draw_${mode}`);
        }
        else
        {
            canvas.remove(template);
        }

        canvas.drawMode = false;
        canvas.renderAll();
        setTemplate(null);
        onMouseSelect(null, true);

    }, [canvas, template, mode]);

    const onDefaultShapeDraw = useCallback((shape) => {

        if (!canvas)
        {
            return;
        }

        let shapeFigure;

        switch (shape) {
            case "rect":
                shapeFigure = initRect();
                break;
            case "triangle":
                shapeFigure = initTriangle();
                break;
            case "circle":
                shapeFigure = initCircle();
                break;
            case "line":
                shapeFigure = initLine();
                break;
            case "text":
                shapeFigure = initText();
                break;
            case "arrow":
                shapeFigure = initArrowLine();
                break;
        }

        canvas.add(shapeFigure);
        canvas.setActiveObject(shapeFigure);
        onMouseSelect(null, true);
        firebase.analytics().logEvent(`draw_${shape}_default`);

    }, [canvas]);

    const onMouseSelect = useCallback((_, fromCode = false) => {

        setMode('mouse');
        canvas.selection = true;
        setTemplate(null);

        if (!fromCode)
        {
            canvas && canvas.remove(template);
            firebase.analytics().logEvent("tools_menu_cursor");
        }

    }, [canvas, template]);


    return {onMouseDown, onMouseMove, onMouseUp, onDefaultShapeDraw, onMouseSelect};
}

export function snapToGrid (number)
{
    return number;
    return Math.round(number / gridSize) * gridSize;
}

fabric.Object.prototype.toObject = (function (toObject) {
    return function (properties) {
        return fabric.util.object.extend(toObject.call(this, properties), {
            id: this.id,
            name: this.name,
            snapAngle: this.snapAngle,
            strokeUniform: this.strokeUniform,
        });
    };
})(fabric.Object.prototype.toObject);
