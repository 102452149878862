import React, {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from "react-redux";

export default function ZoomIndicator ({})
{

    let fadeOutTimeout = 0;

    const [zoomPercent, setZoomPercent] = useState(0);
    const [zoomChanged, setZoomChanged] = useState(false);

    const canvas = useSelector(state => state.canvas.canvas, shallowEqual);
    const zoom = useSelector(state => state.canvas.zoom, shallowEqual);
    const showZoomIndicator = useSelector(state => state.canvas.showZoomIndicator, shallowEqual);

    const openedPremadeData = useSelector(state => state.premades.openedPremadeData, shallowEqual);

    useEffect(() =>
    {
        if (canvas)
        {
            setZoomPercent(Math.round((canvas.getZoom() - 0.25) / (3 - 0.25) * 100));
        }
    }, [canvas]);

    useEffect(() =>
    {
        if (zoom === null)
        {
            return () => false;
        }

        let zoomPercent = Math.round((zoom - 0.25) / (3 - 0.25) * 100);
        setZoomPercent(zoomPercent);

        setZoomChanged(true);
        fadeOutTimeout = setTimeout(() => setZoomChanged(false), 2000);

        return () => {
            clearTimeout(fadeOutTimeout)
        }

    }, [zoom]);

    useEffect(() =>
    {
        if (showZoomIndicator && !openedPremadeData)
        {
            setZoomChanged(true);
            fadeOutTimeout = setTimeout(() => setZoomChanged(false), 3000);
        }
        return () => {
            clearTimeout(fadeOutTimeout)
        }
    }, [showZoomIndicator, openedPremadeData]);

    return (zoomPercent >= 0 && !openedPremadeData) ? (
        <div className={`zoom-indicator ${zoomChanged ? 'fade-in' : 'fade-out'} ${(zoom === null && !zoomPercent) ? 'disappear-immediately' : ''}`}>
            {zoomPercent}%
        </div>
    ) : null;
}
