import * as React from "react";

function SvgIconFill(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <linearGradient
        id="IconFill_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={10.187}
        y1={-35.758}
        x2={10.187}
        y2={53.277}
        gradientTransform="translate(202.776 215.547) scale(5.2245)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M256 41.8c-123.8 0-224.7 100.8-224.7 224.7S132.2 491.1 256 491.1s224.7-100.8 224.7-224.7S379.8 41.8 256 41.8z"
        fill="url(#IconFill_svg__a)"
      />
    </svg>
  );
}

export default SvgIconFill;
