export function objectsCanBeGroupped (activeObj)
{
	return activeObj && activeObj.toGroup !== undefined;
}

export function groupObjects (activeObj)
{
	if (activeObj.toGroup)
	{
		return activeObj.toGroup();
	}

	return activeObj;
}

export function objectsCanBeUngroupped (activeObj)
{
	return activeObj && (activeObj.type === 'group');
}

export function ungroupObjects (canvas, activeObj)
{
	let objectsInGroup = activeObj._objects;
	activeObj._restoreObjectsState();
	canvas.remove(activeObj);

	for (let i = 0; i < objectsInGroup.length; i++)
	{
		canvas.add(objectsInGroup[i]);

		if (objectsInGroup[i].type !== "arrowLine" && objectsInGroup[i].name !== "arrowLineTriangle")
		{
			canvas.item(canvas.size() - 1).hasControls = true;
		}
	}
}



