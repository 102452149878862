import * as React from "react";

function SvgIconRoundCorners(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <linearGradient
        id="IconRoundCorners_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={42.919}
        y1={289.43}
        x2={42.919}
        y2={369.686}
        gradientTransform="scale(5.2245) rotate(-45 -294.042 165.676)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M355.8 151.3h-43.6c-8.9 0-15.9 7-15.5 15.5 0 8.9 7 15.9 15.5 15.5h43.6c45.8 0 83.5 37.7 84.2 84.2 0 45.8-37.7 83.5-84.2 84.2h-43.6c-8.9 0-15.9 7-15.5 15.5 0 8.9 7 15.9 15.5 15.5h43.6c31.8 0 60.6-13.3 81.3-34 21.1-21.1 34-49.5 34-81.3-.5-63.7-52.2-115.4-115.3-115.1z"
        fill="url(#IconRoundCorners_svg__a)"
      />
      <linearGradient
        id="IconRoundCorners_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={16.306}
        y1={289.43}
        x2={16.306}
        y2={369.686}
        gradientTransform="scale(5.2245) rotate(-45 -294.042 165.676)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M156.3 182.4h43.6c8.9 0 15.9-7 15.5-15.5 0-8.9-7-15.9-15.5-15.5h-43.6C92.7 152.1 41 203.8 41 266.6c.7 63.5 52.5 115.3 115.3 115.3h43.6c4.4 0 8.5-1.8 11.1-4.4 3-3 4.4-6.6 4.4-11.1 0-8.9-7-15.9-15.5-15.5h-43.6c-45.8 0-83.5-37.7-84.2-84.2.3-46.3 38-84 84.2-84.3z"
        fill="url(#IconRoundCorners_svg__b)"
      />
    </svg>
  );
}

export default SvgIconRoundCorners;
