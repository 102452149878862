import React from 'react';
import IconReminder from "./icons/IconReminder";
import {useTranslation} from "react-i18next";


export default function MobilePreview ({})
{
	const {t} = useTranslation();

	function share ()
	{
		if (!navigator.share)
		{
			return;
		}

		navigator.share({
			title: "wdyt?",
			text: t("mobilePreview.share"),
			url: window.location.href
		})
			.then(function () {
				console.log("Shareing successfull")
			})
			.catch(function () {
				console.log("Sharing failed")
			})
	}

	return (
		<div className="mobile-preview">
			<b className="logo">
				wdyt<span className="question">?</span>
			</b>
			<div className="text mt-2">{t("mobilePreview.title")}</div>
			<div id="big-ideas" className="text mt-4">{t("mobilePreview.subTitle")}</div>
			<div className="link">
				<div className="text mb-2">{t("mobilePreview.footer")}</div>
				<IconReminder onClick={share} width={40} height={40}/>
			</div>
		</div>
	);
}
