import {shallowEqual, useSelector} from "react-redux";
import {useCallback} from "react";
import {initGrid} from "./grid";
import {throttle} from "../helper";
import {fabric} from "fabric";
import {setCanvasZoom} from "../ducks/canvas";

export function useZoomLogic ()
{
	const maxZoom = 3,
		  minZoom = 1 / 4,
		  throttledInitGrid = throttle(initGrid);

	let lastPosX = 0,
		lastPosY = 0,
		dragging = false;

	const canvas = useSelector(state => state.canvas.canvas, shallowEqual);

	const onMouseDownZoom = useCallback((e) =>
	{
		if (!canvas || e.target || !e.e.altKey)
		{
			return;
		}

		let mouseEvent = e.e;
		canvas.selection = false;
		dragging = true;
		lastPosX = mouseEvent.clientX;
		lastPosY = mouseEvent.clientY;

	}, [canvas]);

	const onMouseMoveZoom = useCallback((e) => {

		if (!canvas || !dragging)
		{
			return;
		}

		let mouseEvent = e.e;
		let vpt = canvas.viewportTransform;
		vpt[4] += mouseEvent.clientX - lastPosX;
		vpt[5] += mouseEvent.clientY - lastPosY;
		canvas.requestRenderAll();

		lastPosX = mouseEvent.clientX;
		lastPosY = mouseEvent.clientY;
	}, [canvas]);

	const onMouseUpZoom = useCallback((e) => {

		if (!canvas || !dragging)
		{
			return;
		}

		canvas.setViewportTransform(canvas.viewportTransform);
		dragging = false;
		canvas.selection = true;

	}, [canvas]);

	const onZoom = useCallback((opt) =>
	{
		let delta = opt.e.deltaY;
		let zoom = canvas.getZoom();
		zoom *= 0.999 ** delta;
		if (zoom > maxZoom) zoom = maxZoom;
		if (zoom < minZoom) zoom = minZoom;
		canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
		opt.e.preventDefault();
		opt.e.stopPropagation();
		let vpt = canvas.viewportTransform;
		canvas.getObjects().forEach(object => object.setCoords());
		canvas.discardActiveObject();
		canvas.renderAll();
		setCanvasZoom(zoom);
		//throttledInitGrid(canvas);
		/*if (zoom < 400 / 1000)
		{
			vpt[4] = 200 - 1000 * zoom / 2;
			vpt[5] = 200 - 1000 * zoom / 2;
		}
		else
		{
			if (vpt[4] >= 0)
			{
				vpt[4] = 0;
			}
			else if (vpt[4] < canvas.getWidth() - 1000 * zoom)
			{
				vpt[4] = canvas.getWidth() - 1000 * zoom;
			}
			if (vpt[5] >= 0)
			{
				vpt[5] = 0;
			}
			else if (vpt[5] < canvas.getHeight() - 1000 * zoom)
			{
				vpt[5] = canvas.getHeight() - 1000 * zoom;
			}
		}*/
	}, [canvas]);

	const zoomToSeeAllObjects = useCallback((canvas) =>
	{
		if (!canvas)
		{
			return;
		}

		let object = new fabric.Group(canvas.getObjects());

		// Pan the entire canvas so that the active object is centered in the viewport.
		// The object's relative position in the canvas should not change.

		let objWidth = object.getScaledWidth();
		let objHeight = object.getScaledHeight();
		let zoom = canvas.getZoom();
		let panX = 0;
		let panY = 0;
		let bounds = object.getBoundingRect(false, false);
		let widthDifference = Math.abs(bounds.width - canvas.width);
		let heightDifference = Math.abs(bounds.height - canvas.height);

		if (heightDifference > widthDifference && heightDifference > 200)
		{
			zoom = (bounds.height + 300) / canvas.height;
			if (bounds.height > canvas.height)
			{
				zoom = 1 / zoom;
			}
		}
		else if (heightDifference < widthDifference && widthDifference > 200)
		{
			zoom = (bounds.width + 300) / canvas.width;
			if (bounds.width > canvas.width)
			{
				zoom = 1 / zoom;
			}
		}

		canvas.setZoom(zoom);

		// WORKS - setViewportTransform
		panX = ((canvas.getWidth() / zoom / 2) - (object.aCoords.tl.x) - (objWidth / 2)) * zoom
		panY = ((canvas.getHeight() / zoom / 2) - (object.aCoords.tl.y) - (objHeight / 2)) * zoom

		canvas.setViewportTransform([zoom, 0, 0, zoom, panX, panY]);

		// ALSO WORKS - (for center/center) relative pan
		/*
		panX = (canvas.getVpCenter().x - object.getCenterPoint().x) * zoom
		panY =  ((canvas.getVpCenter().y - object.getCenterPoint().y) * zoom)
	   canvas.relativePan({x:panX, y:panY})
		*/
		// canvas.requestRenderAll()
		//canvas.calcCoords


		object._restoreObjectsState();

	}, []);

	return {onMouseDownZoom, onMouseMoveZoom, onMouseUpZoom, onZoom, zoomToSeeAllObjects};
}
