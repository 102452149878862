import React, {useState} from 'react';
import {IconResize, IconTextSize} from "../icons";
import {MenuItem} from "react-contextmenu";
import {shallowEqual, useSelector} from "react-redux";
import AutosizeInput from "react-input-autosize";
import {setTextEditingMode} from "../../ducks/main";
import {useTranslation} from "react-i18next";

export default function CanvasContextMenuDimensions ({setDimensions})
{
	const activeObject = useSelector(state => state.canvas.activeObject, shallowEqual);

	const [inputEl, setInputEl] = useState(null);
	const [inputEl2, setInputEl2] = useState(null);
	const {t} = useTranslation();

	function getWidth ()
	{
		if (activeObject._objects !== undefined || activeObject.type === 'circle')
		{
			return ((activeObject.type === 'circle' ? activeObject.radius : activeObject.width) * activeObject.scaleX).toFixed(0);
		}
		{
			return activeObject.width === 0 ? '' : activeObject.width.toFixed(0);
		}
	}

	function getHeight ()
	{
		if (activeObject._objects !== undefined || activeObject.type === 'circle')
		{
			return ((activeObject.type === 'circle' ? activeObject.radius : activeObject.height) * activeObject.scaleY).toFixed(0);
		}
		{
			return activeObject.height === 0 ? '' : activeObject.height.toFixed(0);
		}
	}

	function formatValue (e)
	{
		return e.target.value.slice(0, 5).replace('-', '');
	}

	return (
		<MenuItem className={`context-menu-item dimensions`}>
			<div>
				<IconResize width={20} height={20}/>
				<span>{t("contextMenu.size")}</span>
			</div>
			<div onClick={e => e.stopPropagation()} onContextMenu={e => e.stopPropagation()} className="d-flex input-wrapper">
				<span className="input-label">W:</span>
				<AutosizeInput
					inputRef={setInputEl}
					type="number"
					step="1"
					min="1"
					max="999"
					placeholder="1"
					onClick={() => inputEl.select()}
					value={getWidth()}
					onFocus={() => setTextEditingMode(true)}
					onBlur={() => setTextEditingMode(false)}
					onChange={e => setDimensions({width: formatValue(e), height : getHeight()})}
				/>
				<React.Fragment>
					<span className="input-label ml-2">H:</span>
					<AutosizeInput
						inputRef={setInputEl2}
						type="number"
						step="1"
						min="1"
						max="999"
						placeholder="1"
						onClick={() => inputEl2.select()}
						value={getHeight()}
						onFocus={() => setTextEditingMode(true)}
						onBlur={() => setTextEditingMode(false)}
						onChange={e => setDimensions({width: getWidth(), height : formatValue(e)})}
					/>
				</React.Fragment>
			</div>
		</MenuItem>
	);
}
