import * as React from "react";

function SvgIconRemoveGuideline(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <linearGradient
        id="IconRemoveGuideline_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={245.254}
        y1={304.538}
        x2={245.254}
        y2={219.538}
        gradientTransform="matrix(.06507 -4.6473 -4.6087 -.06453 1455.36 1412.645)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M105.8 75.3c-5.6 5-6.2 13.9-1.2 19.5l303.6 340.8c5 5.6 13.7 6.2 19.3 1.2 2.8-2.7 4.7-6.4 4.8-10.2 0-3.3-1.3-7-3.6-9.3L125.1 76.9c-5-6.1-13.7-6.7-19.3-1.6z"
        fill="url(#IconRemoveGuideline_svg__a)"
      />
      <g>
        <linearGradient
          id="IconRemoveGuideline_svg__b"
          gradientUnits="userSpaceOnUse"
          x1={237.071}
          y1={336.417}
          x2={237.071}
          y2={251.417}
          gradientTransform="matrix(-4.6238 .4708 .4669 4.5854 1225.87 -1200.303)"
        >
          <stop offset={0} stopColor="#00efd1" />
          <stop offset={1} stopColor="#00acea" />
        </linearGradient>
        <path
          d="M105.6 436.7c5.6 5 14.5 4.5 19.5-1.1L428.6 94.8c5-5.6 4.6-14.4-1-19.4-3.1-2.5-6.9-3.9-10.6-3.5-3.2.3-6.8 2.1-8.9 4.6L105 417.3c-5.5 5.7-5.1 14.4.6 19.4z"
          fill="url(#IconRemoveGuideline_svg__b)"
        />
      </g>
    </svg>
  );
}

export default SvgIconRemoveGuideline;
