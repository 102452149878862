import {createAction, createReducer} from "redux-act";

export const setGuidelines = createAction('set guidelines', guidelines => guidelines);
export const setGuidelinesVisibility = createAction('set guidelines visibility', guidelinesVisibility => guidelinesVisibility);
export const setSelectedGuideline = createAction('set selected guideline on mouse right click', selectedGuideline => selectedGuideline);

const initialState = {
    guidelines : [],
    guidelinesVisibility : true,
    selectedGuideline : null
};

const canvas = createReducer({
    [setGuidelines]: (state, payload) => ({...state, guidelines: payload}),
    [setGuidelinesVisibility]: (state, payload) => ({...state, guidelinesVisibility: payload}),
    [setSelectedGuideline]: (state, payload) => ({...state, selectedGuideline: payload}),
}, initialState);

export default canvas;
