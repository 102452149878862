import {createAction, createReducer} from "redux-act";

export const setIdeasModalOpened = createAction('open ideas modal', data => data);
export const setEditIdeaModalOpened = createAction('open ideas modal', data => data);
export const setEditableIdea = createAction('set editable idea', data => data);
export const setIdeasUpdated = createAction('set ideas updated flag to force ideas modal update', data => data);
export const setNotLoggedForNewIdeaModalOpened = createAction('open modal when unsigned user trying to save data as idea', data => data);
export const setForceSaveIdea = createAction('force save idea after user signed in', data => data);

const initialState = {
    ideasModalOpened: false,
    editIdeaModelOpened: false,
    editableIdea: null,
    ideasUpdated: false,
    notLoggedForNewIdeaModalOpened: false,
    forceSaveIdea : false
};

const ideas = createReducer({
    [setIdeasModalOpened]: (state, payload) => ({...state, ideasModalOpened: payload}),
    [setEditIdeaModalOpened]: (state, payload) => ({...state, editIdeaModelOpened: payload}),
    [setEditableIdea]: (state, payload) => ({...state, editableIdea: payload}),
    [setIdeasUpdated]: (state, payload) => ({...state, ideasUpdated: payload}),
    [setNotLoggedForNewIdeaModalOpened]: (state, payload) => ({...state, notLoggedForNewIdeaModalOpened: payload}),
    [setForceSaveIdea]: (state, payload) => ({...state, forceSaveIdea: payload}),
}, initialState);

export default ideas;
