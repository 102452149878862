import * as React from "react";

function SvgIconHideGuidelines(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={512}
      height={512}
      {...props}
    >
      <linearGradient
        id="IconHideGuidelines_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={22}
        x2={49}
        y2={80}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M93.9 49.2c-.2-.2-5-6.8-12.9-13.5C70.4 26.7 59.3 22 49 22 24.3 22 4.9 48.1 4.1 49.2c-.8 1.1-.8 2.5 0 3.5.2.3 5 6.9 12.9 13.6C27.6 75.3 38.7 80 49 80c24.7 0 44.1-26.1 44.9-27.2.8-1.1.8-2.5 0-3.6zM49 74c-18.2 0-34-17.3-38.7-23C15 45.3 30.7 28 49 28c18.2 0 34 17.3 38.7 23C83 56.7 67.3 74 49 74z"
        fill="url(#IconHideGuidelines_svg__a)"
      />
      <linearGradient
        id="IconHideGuidelines_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={33}
        x2={49}
        y2={69}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M49 33c-9.9 0-18 8.1-18 18s8.1 18 18 18 18-8.1 18-18-8.1-18-18-18zm0 30c-6.6 0-12-5.4-12-12s5.4-12 12-12 12 5.4 12 12-5.4 12-12 12z"
        fill="url(#IconHideGuidelines_svg__b)"
      />
    </svg>
  );
}

export default SvgIconHideGuidelines;
