import React, {useEffect, useState, Suspense} from 'react';
import {setFirstImpressionProcessed, setShareId} from "./ducks/main";
import {KEY, loadCanvasData} from "./logic/share";
import {shallowEqual, useSelector} from "react-redux";
import ReactTooltip from "react-tooltip";
import {Actions, PremadesModal, Canvas, Share, AddPremadeModal, OpenedPremadeDialog} from "./containers";
import {NotFound, Loader} from "./components";
import {loadPremadesFromDb} from "./logic/premades";
import CryptoJS from "crypto-js/crypto-js";
import {setOpenedPremadeData} from "./ducks/premades";
import {mobilecheck} from "./helper";
import MobilePreview from "./components/MobilePreview";
import IdeasButton from "./containers/IdeasButton";
import IdeasModal from "./containers/IdeasModal";
import EditIdeaModal from "./containers/EditIdeaModal";
import NotLoggedForNewIdeaModal from "./containers/NotLoggedForNewIdeaModal";
import ZoomIndicator from "./containers/ZoomIndicator";
import {setSavedLocalData} from "./ducks/canvas";
import {customProps} from "./logic/canvas";

export default function App ({})
{
    const savedLocalData = useSelector(state => state.canvas.localData, shallowEqual);
    const firstImpressionProcessed = useSelector(state => state.main.firstImpressionProcessed, shallowEqual);
    const canvas = useSelector(state => state.canvas.canvas, shallowEqual);

    const [loading, setLoading] = useState(true);
    const [savedData, setSavedData] = useState(savedLocalData);
    const [notFound, setNotFound] = useState(false);

    /**
     * Initial check of share url / premade url
     */
    useEffect(() =>
    {
        let sharedUrlExists = checkShareUrl(),
            premadesUrlExists = checkPremadeUrl();

        if (firstImpressionProcessed === false && !mobilecheck())
        {
            sharedUrlExists = true;
            loadSharedData(decodeURIComponent("U2FsdGVkX19pEqgqV9PqQzVXsP6t6qnV%2BcnQqDOJGdKTlVmSVZYKEy22Hail0cXf"));
        }

        if (sharedUrlExists === false && premadesUrlExists === false)
        {
            setLoading(false);
        }
    }, []);

    /**
     * check if user opened share url and load share data
     */
    function checkShareUrl ()
    {
        let path = window.location.pathname,
            matches = path.match(/\/share\/(.*)/);

        if (matches === null)
        {
            return false;
        }

        const shareId = decodeURIComponent(matches[1]);
        if (shareId === null)
        {
            return false;
        }

        setLoading(true);
        setShareId(shareId);
        loadSharedData(shareId);
    }

    /**
     * check if user opened premade url and load premade data
     */
    function checkPremadeUrl ()
    {
        let path = window.location.pathname,
            matches = path.match(/\/premade\/(.*)/);

        if (matches === null)
        {
            return false;
        }

        let premadeId = decodeURIComponent(matches[1]);
        if (premadeId === null)
        {
            return false;
        }

        setLoading(true);
        premadeId = CryptoJS.AES.decrypt(premadeId, KEY).toString(CryptoJS.enc.Utf8);
        //setOpenedPremadeId();
        //setShareId(shareId);
        loadPremadeData(premadeId);
    }

    /**
     * load shared data (if user opened share url)
     */
    async function loadSharedData (shareId)
    {
        const data = await loadCanvasData(shareId);

        if (data === null || data === undefined)
        {
            document.location.hash = '#404';
            setNotFound(true);
            setLoading(false);
            return;
        }

        setSavedData(data);
        setLoading(false);
    }

    /**
     * load premade data (if user opened premade url)
     */
    async function loadPremadeData (premadeId)
    {
        const [docs, _] = await loadPremadesFromDb([premadeId]);

        if (!docs)
        {
            document.location.hash = '#404';
            setNotFound(true);
            setLoading(false);
            return;
        }

        setOpenedPremadeData(docs[0]);
        setLoading(false);
    }

    /**
     * if user opened premade url or share url and data was not found - show Not Found comppnent
     */
    if (notFound)
    {
        return <NotFound/>;
    }

    /**
     * Show loader (when system loads premade / share data)
     */
    if (loading)
    {
        return <Loader/>;
    }

    return (
        <Suspense fallback={null}>
            <Canvas savedData={savedData || savedLocalData}/>
            <PremadesModal/>
            <AddPremadeModal/>
            <IdeasModal/>
            <EditIdeaModal/>
            <NotLoggedForNewIdeaModal/>
            {
                !mobilecheck() &&
                <React.Fragment>
                    <IdeasButton/>
                    <Share/>
                    <Actions/>
                    <OpenedPremadeDialog/>
                    <ZoomIndicator/>
                </React.Fragment>
            }
            {
                mobilecheck() &&
                <MobilePreview/>
            }
            <ReactTooltip className='tooltip' place="right" type="dark" effect="solid" html={true}/>
        </Suspense>
    );
}
