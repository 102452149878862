import * as React from "react";

function SvgIconLink(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={24}
      height={24}
      {...props}
    >
      <linearGradient
        id="IconLink_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={62.325}
        y1={88.75}
        x2={62.325}
        y2={8.494}
        gradientTransform="matrix(1 0 0 -1 0 104)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M78.1 50.9L72.2 45c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2l5.9 5.9c6.2 6.2 6.2 16.4 0 22.8-6.2 6.2-16.4 6.2-22.8 0L45.2 72c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2l5.9 5.9c4.3 4.3 10 6.4 15.6 6.4 5.7 0 11.3-2.1 15.6-6.4 8.6-8.7 8.6-22.7 0-31.2z"
        fill="url(#IconLink_svg__a)"
      />
      <linearGradient
        id="IconLink_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={35.712}
        y1={88.75}
        x2={35.712}
        y2={8.494}
        gradientTransform="matrix(1 0 0 -1 0 104)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M46.9 28.1l5.9 5.9c1.2 1.2 3.1 1.2 4.2 0 1.2-1.2 1.2-3.1 0-4.2l-5.9-5.9c-8.7-8.5-22.7-8.5-31.2 0-8.5 8.7-8.5 22.7 0 31.2l5.9 5.9c.6.6 1.4.9 2.1.9.8 0 1.5-.3 2.1-.9 1.2-1.2 1.2-3.1 0-4.2l-5.9-5.9c-6.2-6.2-6.2-16.4 0-22.8 6.3-6.2 16.5-6.2 22.8 0z"
        fill="url(#IconLink_svg__b)"
      />
      <linearGradient
        id="IconLink_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={88.75}
        x2={49}
        y2={8.494}
        gradientTransform="matrix(1 0 0 -1 0 104)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M56.6 63.6c.8 0 1.5-.3 2.1-.9 1.2-1.2 1.2-3.1 0-4.2L43.5 43.3c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2l15.2 15.2c.6.6 1.3.9 2.1.9z"
        fill="url(#IconLink_svg__c)"
      />
    </svg>
  );
}

export default SvgIconLink;
