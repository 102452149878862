import * as React from "react";

function SvgIconInvert(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={512}
      height={512}
      {...props}
    >
      <linearGradient
        id="IconInvert_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={48.15}
        y1={9.75}
        x2={48.15}
        y2={96.394}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M72.4 61.6c.6.6 1.3.9 2.1.9s1.6-.3 2.1-.9l7.8-7.8c1.2-1.2 1.2-3.1 0-4.2L49.1 14.2c-1.2-1.2-3.1-1.2-4.2 0L37.1 22c-.5.5-.9 1.3-.9 2L32 19.8c-3.4-3.4-8.9-3.4-12.2 0-3.4 3.4-3.4 8.9 0 12.2l6.9 6.9-14.8 14.8c-1.2 1.2-1.2 3.1 0 4.2l28.6 28.6c.6.6 1.3.9 2.1.9s1.6-.3 2.1-.9L71 60.2l1.4 1.4zM47 20.6l31.1 31.1-3.5 3.5-31.2-31.1 3.6-3.5zm-23.1 7.2c-1-1-1-2.7 0-3.8 1-1 2.7-1 3.8 0l6.9 6.9-3.8 3.8-6.9-6.9zm18.7 52.4L18.3 55.8l12.6-12.6 6.6 6.6c-.5 1.1-.8 2.3-.8 3.6 0 4.7 3.9 8.6 8.6 8.6s8.6-3.9 8.6-8.6c0-4.7-3.9-8.6-8.6-8.6-1.3 0-2.5.3-3.6.8L35.1 39l7.3-7.4L66.8 56 42.6 80.2zm0-26.9c0-1.4 1.2-2.6 2.6-2.6 1.4 0 2.6 1.2 2.6 2.6 0 1.4-1.2 2.6-2.6 2.6-1.4 0-2.6-1.2-2.6-2.6z"
        fill="url(#IconInvert_svg__a)"
      />
      <linearGradient
        id="IconInvert_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={76.2}
        y1={9.75}
        x2={76.2}
        y2={96.394}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M83.8 74.3l-5.5-5.5c-1.2-1.2-3.1-1.2-4.2 0l-5.5 5.5c-2 2-3.1 4.7-3.1 7.6 0 2.9 1.1 5.6 3.1 7.6 2.1 2.1 4.9 3.2 7.6 3.2s5.5-1.1 7.6-3.2c2-2 3.1-4.7 3.1-7.6 0-2.9-1-5.6-3.1-7.6zm-4.2 11c-1.8 1.8-4.9 1.8-6.8 0-1.8-1.8-1.8-4.9 0-6.8l3.4-3.4 3.4 3.4c1.8 1.9 1.8 5 0 6.8z"
        fill="url(#IconInvert_svg__b)"
      />
    </svg>
  );
}

export default SvgIconInvert;
