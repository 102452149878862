import React, {useState} from 'react';
import {IconTextSize} from "../icons";
import {MenuItem} from "react-contextmenu";
import {shallowEqual, useSelector} from "react-redux";
import AutosizeInput from "react-input-autosize";
import {setTextEditingMode} from "../../ducks/main";
import {useTranslation} from "react-i18next";

export default function CanvasContextMenuTextSize ({setTextSize})
{
	const activeObject = useSelector(state => state.canvas.activeObject, shallowEqual);

	const [inputEl, setInputEl] = useState(null);
	const {t} = useTranslation();

	function getTextSize ()
	{
		return Math.round(activeObject.fontSize);
	}

	return (
		<MenuItem className={`context-menu-item text-size`}>
			<div>
				<IconTextSize width={20} height={20}/>
				<span>{t("contextMenu.textSize")}</span>
			</div>
			<div onClick={e => e.stopPropagation()} onContextMenu={e => e.stopPropagation()} className="d-flex input-wrapper">
				<button onClick={() => setTextSize((getTextSize() || 0) - 1)} type="button">-</button>
				<AutosizeInput
					inputRef={setInputEl}
					type="number"
					step="1"
					min="0"
					max="999"
					placeholder="0"
					onClick={() => inputEl.select()}
					value={getTextSize()}
					onFocus={() => setTextEditingMode(true)}
					onBlur={() => setTextEditingMode(false)}
					onChange={e => setTextSize(e.target.value.slice(0, 3).replace('-', ''))}
				/>
				<button onClick={() => setTextSize((getTextSize() || 0) + 1)} type="button">+</button>
			</div>
		</MenuItem>
	);
}
