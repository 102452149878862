import {fabric} from "fabric";

export function initTriangle (x = null, y = null)
{
    let params = {};
    if (x !== null && y !== null)
    {
        params = {top : y, left : x, width: 0, height: 0};
    }
    else
    {
        params = {top : 100, left : 100, width : 200, height : 120};
    }

    return new fabric.Triangle({
        ...params,
        strokeWidth : 3,
        opacity: 1,
        stroke : '#eaff00',
        fill : 'transparent',
        strokeUniform : true,
        noScaleCache: false
    });
}
