import * as React from "react";

function SvgIconCopy(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={24}
      height={24}
      {...props}
    >
      <linearGradient
        id="IconCopy_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={10.8}
        x2={49}
        y2={91.2}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M87.7 31.2H70.3V13.8c0-1.7-1.3-3-3-3h-57c-1.7 0-3 1.3-3 3v54c0 1.7 1.3 3 3 3h17.4v17.4c0 1.7 1.3 3 3 3h57c1.7 0 3-1.3 3-3v-54c0-1.7-1.3-3-3-3zM13.3 16.8h51v48h-51v-48zm71.4 68.4h-51V70.8h33.6c1.7 0 3-1.3 3-3V37.2h14.4v48z"
        fill="url(#IconCopy_svg__a)"
      />
    </svg>
  );
}

export default SvgIconCopy;
