import {createAction, createReducer} from "redux-act";

export const setPremadesModalOpened = createAction('open premades modal', data => data);
export const setAddPremadeModalOpened = createAction('open add premade modal', data => data);
export const setAddedPremadesIds = createAction('saved added premades ids', data => data);
export const setPremadeAdded = createAction('premade added', premadeAdded => premadeAdded);
export const setCreatedPremadeData = createAction('set created premade data', createdPremadeData => createdPremadeData);
export const setOpenedPremadeData = createAction('Set opened premade data', openedPremadeData => openedPremadeData);

const initialState = {
    premadesModalOpened: false,
    addedPremadesIds : [],
    premadeAdded: false,
    addPremadeModalOpened: false,
    createdPremadeData: false,
    openedPremadeData: null,
};

const premades = createReducer({
    [setPremadesModalOpened]: (state, payload) => ({...state, premadesModalOpened: payload}),
    [setAddedPremadesIds]: (state, payload) => ({...state, addedPremadesIds: payload}),
    [setPremadeAdded]: (state, payload) => ({...state, premadeAdded: payload}),
    [setAddPremadeModalOpened]: (state, payload) => ({...state, addPremadeModalOpened: payload}),
    [setCreatedPremadeData]: (state, payload) => ({...state, createdPremadeData: payload}),
    [setOpenedPremadeData]: (state, payload) => ({...state, openedPremadeData: payload}),
}, initialState);

export default premades;
