import * as React from "react";

function SvgIconOpenExternal(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={512}
      height={512}
      {...props}
    >
      <linearGradient
        id="IconOpenExternal_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={64.4}
        y1={11.667}
        x2={64.4}
        y2={89.374}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M88.7 15.6c0-.1-.1-.2-.1-.3 0-.1-.1-.2-.1-.3s-.1-.2-.2-.3c0-.1-.1-.1-.1-.2-.2-.3-.5-.6-.8-.8-.1 0-.1-.1-.2-.1-.1-.1-.2-.1-.3-.2-.1 0-.2-.1-.3-.1-.1 0-.2-.1-.3-.1-.2 0-.4-.1-.6-.1H64.1c-1.7 0-3 1.3-3 3s1.3 3 3 3h14.5L40.9 56.9c-1.2 1.2-1.2 3.1 0 4.2.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l37.7-37.7v14.5c0 1.7 1.3 3 3 3s3-1.3 3-3V16.2c0-.2 0-.4-.1-.6z"
        fill="url(#IconOpenExternal_svg__a)"
      />
      <linearGradient
        id="IconOpenExternal_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={43.9}
        y1={11.667}
        x2={43.9}
        y2={89.374}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M75.6 50.5c-1.7 0-3 1.3-3 3v33.3H15.2V29.4h33.3c1.7 0 3-1.3 3-3s-1.3-3-3-3H12.2c-1.7 0-3 1.3-3 3v63.4c0 1.7 1.3 3 3 3h63.4c1.7 0 3-1.3 3-3V53.5c0-1.7-1.3-3-3-3z"
        fill="url(#IconOpenExternal_svg__b)"
      />
    </svg>
  );
}

export default SvgIconOpenExternal;
