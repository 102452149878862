import * as React from "react";

function SvgIconUser(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={512}
      height={512}
      {...props}
    >
      <linearGradient
        id="IconUser_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={11.667}
        x2={49}
        y2={95.691}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M49 58.2c-24.7 0-38.3 11.7-38.3 32.9 0 1.7 1.3 3 3 3h70.6c1.7 0 3-1.3 3-3 0-21.2-13.6-32.9-38.3-32.9zM16.8 88.1C18 72.2 28.8 64.2 49 64.2s31 8 32.2 23.9H16.8z"
        fill="url(#IconUser_svg__a)"
      />
      <linearGradient
        id="IconUser_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={11.667}
        x2={49}
        y2={95.691}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M49 53.8c11 0 19.9-9.7 19.9-21.6 0-11.6-8.6-20.3-19.9-20.3s-19.9 8.7-19.9 20.3c0 11.9 8.9 21.6 19.9 21.6zm0-35.9c7.9 0 13.9 6.1 13.9 14.3 0 8.6-6.2 15.6-13.9 15.6s-13.9-7-13.9-15.6c0-8.3 5.8-14.3 13.9-14.3z"
        fill="url(#IconUser_svg__b)"
      />
    </svg>
  );
}

export default SvgIconUser;
