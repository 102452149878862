import * as React from "react";

function SvgIconCircle(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <linearGradient
        id="IconCircle_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={29.594}
        y1={414.32}
        x2={29.594}
        y2={325.285}
        gradientTransform="matrix(5.2245 0 0 -5.2245 101.388 2193.347)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M256 41.8c-123.8 0-224.7 100.8-224.7 224.7S132.2 491.1 256 491.1s224.7-100.8 224.7-224.7S379.8 41.8 256 41.8zm0 418c-106.6 0-193.3-86.7-193.3-193.3S149.4 73.1 256 73.1s193.3 86.7 193.3 193.3S362.6 459.8 256 459.8z"
        fill="url(#IconCircle_svg__a)"
      />
    </svg>
  );
}

export default SvgIconCircle;
