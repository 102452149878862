import {createAction, createReducer} from "redux-act";

export const setMode = createAction('set mode', mode => mode);
export const setTemplate = createAction('set template', fabricObject => fabricObject);
export const setCanvas = createAction('set canvas', canvas => canvas);
export const setSavedLocalData = createAction('set saved local data', data => data);
export const setChangesExists = createAction('set changes exists', changesExists => changesExists);
export const setActiveObject = createAction('set canvas active object', activeObject => activeObject);
export const setLastActiveObjectChangeTime = createAction('set last time active object was changed', lastActiveObjectChangeTime => lastActiveObjectChangeTime);
export const setMemorizedObject = createAction('set memorized (copied) object', memorizedObject => memorizedObject);
export const setCanvasZoom = createAction('set canvas zoom', zoom => zoom);
export const setShowZoomIndicator = createAction('set show zoom indicator. used after page load / premade url dialog close', showZoomIndicator => showZoomIndicator);

const initialState = {
    mode: 'mouse',
    template: null,
    canvas: null,
    localData: null,
    changesExists: false,
    activeObject : null,
    lastActiveObjectChangeTime : null,
    memorizedObject : null,
    zoom : null,
    showZoomIndicator : null
};

const canvas = createReducer({
    [setMode]: (state, payload) => ({...state, mode: payload}),
    [setTemplate]: (state, payload) => ({...state, template: payload}),
    [setCanvas]: (state, payload) => ({...state, canvas: payload}),
    [setSavedLocalData]: (state, payload) => ({...state, localData: payload}),
    [setChangesExists]: (state, payload) => ({...state, changesExists: payload}),
    [setActiveObject]: (state, payload) => ({...state, activeObject: payload}),
    [setLastActiveObjectChangeTime]: (state, payload) => ({...state, lastActiveObjectChangeTime: payload}),
    [setMemorizedObject]: (state, payload) => ({...state, memorizedObject: payload}),
    [setCanvasZoom]: (state, payload) => ({...state, zoom: payload}),
    [setShowZoomIndicator]: (state, payload) => ({...state, showZoomIndicator: payload}),
}, initialState);

export default canvas;
