import React from 'react';
import Modal from 'react-modal';
import {usePremades} from "../logic/premades";
import {shallowEqual, useSelector} from "react-redux";
import {setOpenedPremadeData} from "../ducks/premades";
import {useTranslation} from "react-i18next";
import {setShowZoomIndicator} from "../ducks/canvas";

Modal.defaultStyles.overlay.backgroundColor = '#00000060';
Modal.setAppElement('#root');

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: 0,
		minWidth: 500
	},
	overlay: {
		zIndex: 10
	}
};

export default function OpenedPremadeDialog ({})
{
	const openedPremadeData = useSelector(state => state.premades.openedPremadeData, shallowEqual);
	const {addPremadeOnCanvas} = usePremades();

	const {t} = useTranslation();

	function add ()
	{
		addPremadeOnCanvas(openedPremadeData);
		close();
	}

	function close ()
	{
		setOpenedPremadeData(null);
		setShowZoomIndicator(true);
	}

	if (!openedPremadeData)
	{
		return null;
	}

	return (
	    <Modal
		    isOpen={true}
		    style={customStyles}
		    onAfterOpen={() => false}
		    onRequestClose={() => false}
	    >

		    <div className="modal">

			    <div className="modal-header">
				    <span className="title">{openedPremadeData.name}</span>
			    </div>
			    <div className="modal-body d-block">
					<p className="mb-4">{t("openedPremadeDialog.desc")}</p>
					<div className="d-flex">
						<div className="premade-image-wrapper w-50">
							<img className="premade-image" src={openedPremadeData && openedPremadeData.base64}/>
						</div>
						<div className="pl-4 w-50">
						</div>
					</div>
			    </div>
			    <div className="modal-actions">

					<button onClick={close} className="button hover-opacity">
						{t("cancel")}
					</button>

					<button onClick={add} className="button hover-opacity primary">
						{t("add")}
					</button>

			    </div>
		    </div>
	    </Modal>
    )
}
