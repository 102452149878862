import * as React from "react";

function SvgIconArrowBackwards(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={512}
      height={512}
      {...props}
    >
      <linearGradient
        id="IconArrowBackwards_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={18.6}
        x2={49}
        y2={83.4}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M85.4 48.9L56 19.5c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2L76.1 48H14.7c-1.7 0-3 1.3-3 3s1.3 3 3 3h61.4L51.8 78.3c-1.2 1.2-1.2 3.1 0 4.2.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l29.4-29.4c1.2-1.2 1.2-3.1 0-4.2z"
        fill="url(#IconArrowBackwards_svg__a)"
      />
    </svg>
  );
}

export default SvgIconArrowBackwards;
