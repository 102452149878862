import * as React from "react";

function SvgIconLine(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <linearGradient
        id="IconLine_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={29.594}
        y1={424.832}
        x2={29.594}
        y2={318.477}
        gradientTransform="matrix(5.2245 0 0 -5.2245 101.388 2193.347)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M507.1 24.1c-7.2-7.8-19.6-8.5-27.5-1.3L6.3 460.2c-7.8 7.2-8.5 19.6-1.3 27.5 3.9 3.9 9.2 6.5 14.4 6.5 4.6 0 9.8-2 13.1-5.2L505.1 51.6c8.5-7.2 9.1-19.6 2-27.5z"
        fill="url(#IconLine_svg__a)"
      />
    </svg>
  );
}

export default SvgIconLine;
