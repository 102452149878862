import * as React from "react";

function SvgIconText(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <text
        transform="translate(48.001 494.166)"
        fontSize={685.595}
        fontFamily="Nunito-Bold"
        fill="#fff"
      >
        {"T"}
      </text>
      <linearGradient
        id="IconText_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={48.001}
        y1={254.208}
        x2={468.271}
        y2={254.208}
      >
        <stop offset={0.011} stopColor="#3eb0ca" />
        <stop offset={0.5} stopColor="#0099b9" />
        <stop offset={0.994} stopColor="#007c84" />
      </linearGradient>
      <path
        d="M225.9 485.6c-8-8-12-18.6-12-31.9V83.5H89.1C61.7 83.5 48 71.4 48 47.2 48 23 61.7 10.9 89.1 10.9h338c27.4 0 41.1 12.1 41.1 36.3 0 24.2-13.7 36.3-41.1 36.3H302.4v370.2c0 13.3-4 23.9-12 31.9s-18.6 12-31.9 12c-13.7 0-24.6-4-32.6-12z"
        fill="url(#IconText_svg__a)"
      />
    </svg>
  );
}

export default SvgIconText;
