import * as React from "react";

function SvgIconRect(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <linearGradient
        id="IconRect_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={29.594}
        y1={530.07}
        x2={29.594}
        y2={218.447}
        gradientTransform="matrix(5.2245 0 0 -5.2245 101.388 2193.347)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M0 512h512V0H0v512zm109.7-402.3h292.6v292.6H109.7V109.7z"
        fill="url(#IconRect_svg__a)"
      />
    </svg>
  );
}

export default SvgIconRect;
