import * as React from "react";

function SvgIconAddVerticalGuideline(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <linearGradient
        id="IconAddVerticalGuideline_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={245.283}
        y1={306.356}
        x2={245.283}
        y2={221.355}
        gradientTransform="matrix(3.1338 -3.4322 -3.4038 -3.1078 383.19 1915.888)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M255.7 14c-7.5 0-13.8 6.3-13.8 13.8l.8 456.4c0 7.5 6.1 13.8 13.7 13.7 3.9-.2 7.8-1.7 10.3-4.4 2.2-2.4 3.7-6.1 3.5-9.4l-1.2-456c.4-7.9-5.8-14.1-13.3-14.1z"
        fill="url(#IconAddVerticalGuideline_svg__a)"
      />
    </svg>
  );
}

export default SvgIconAddVerticalGuideline;
