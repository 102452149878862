import {shallowEqual, useSelector} from "react-redux";
import {useCallback} from "react";

export function useUpDown ()
{
	const canvas = useSelector(state => state.canvas.canvas, shallowEqual);
	const activeObject = useSelector(state => state.canvas.activeObject, shallowEqual);

	const moveItemUp = useCallback((callback) =>
	{
		if (!activeObject)
		{
			return;
		}

		canvas.bringForward(activeObject, 1);
	}, [canvas, activeObject]);

	const moveItemDown = useCallback((e) =>
	{
		if (!activeObject)
		{
			return;
		}

		canvas.sendBackwards(activeObject, 1);
	}, [canvas, activeObject]);

	return {moveItemUp, moveItemDown};
}
