import firebase from "firebase/app";
import * as firebaseServices from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyD_z129o21onyGLGVlwQLJkrOwXA6diank",
    authDomain: "auth.wdyt.pro",
    databaseURL: "https://wdyt-69f84.firebaseio.com",
    projectId: "wdyt-69f84",
    storageBucket: "wdyt-69f84.appspot.com",
    messagingSenderId: "236726485207",
    appId: "1:236726485207:web:07b12b2c958bfe2b663092",
    measurementId: "G-NZHBYDJGKG"
};

firebase.initializeApp(firebaseConfig);

export const db = firebaseServices.firestore();
