import React, {useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import {loadIdeasFromDb} from "../logic/ideas";
import {shallowEqual, useSelector} from "react-redux";
import * as firebase from "firebase";
import InfiniteScroll from 'react-infinite-scroller';
import ReactTooltip from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import IconTwoArrows from "../components/icons/IconTwoArrows";
import {setIdeasModalOpened, setIdeasUpdated} from "../ducks/ideas";
import {IdeaItem, Loader} from "../components";
import {useTranslation} from "react-i18next";
import AuthButton from "../components/AuthButton";

Modal.defaultStyles.overlay.backgroundColor = '#00000060';
Modal.setAppElement('#root');

const customStyles = {
	content: {
		top: 'unset',
		left: 'unset',
		right: '110px',
		width: '600px',
		padding: 0,
		bottom: '100px',
		maxHeight: 'calc(100% - 140px)',
		borderRadius : 10
	},
	overlay: {
		zIndex: 10
	}
};

export default function IdeasModal ({})
{
	const user = useSelector(state => state.main.user, shallowEqual);

	const ideasModalOpened = useSelector(state => state.ideas.ideasModalOpened, shallowEqual);
	const ideasUpdated = useSelector(state => state.ideas.ideasUpdated, shallowEqual);

	const [initialLoaded, setInitialLoaded] = useState(false);
	const [moreExists, setMoreExists] = useState(true);
	const [loading, setLoading] = useState(false);
	const [ideas, setIdeas] = useState([]);
	const [lastDoc, setLastDoc] = useState(null);

	const contentEl = useRef();

	const {t} = useTranslation();

	async function loadIdeas (force = false)
	{
		if (loading)
		{
			return;
		}

		setLoading(true);

		let newIdeas = [],
			updatedIdeas = [],
			startAfter = force ? null : lastDoc;

		let [fetchedIdeas, newLastDoc] = await loadIdeasFromDb([], user, startAfter, 10);

		newIdeas = fetchedIdeas;
		setLastDoc(newLastDoc);

		if (!force)
		{
			newIdeas = newIdeas.filter(idea => ideas.find(existingIdea => existingIdea.id === idea.id) === undefined);
			updatedIdeas = [...ideas, ...newIdeas];
		}
		else
		{
			updatedIdeas = newIdeas;
		}

		setIdeas(updatedIdeas);

		if (newIdeas.length === 0)
		{
			setMoreExists(false);
		}
		setLoading(false);
		setInitialLoaded(true);
	}

	useEffect(() =>
	{
		if (ideasModalOpened)
		{
			loadIdeas();
		}
	}, [ideasModalOpened]);

	useEffect(() =>
	{
		if (initialLoaded && ideasUpdated)
		{
			loadIdeas(true);

			setTimeout(() =>
			{
				if (ideasUpdated === "DELETED")
				{
					ReactTooltip.show(contentEl.current);
				}
			}, 1000);

			setIdeasUpdated(false);
		}

	}, [initialLoaded, ideasUpdated]);

	function onClose ()
	{
		ReactTooltip.hide(contentEl.current);
		setIdeasModalOpened(false);
	}

	function getBodyHeight ()
	{
		if (loading && !initialLoaded)
		{
			return Math.min(window.innerHeight - 320, 200);
		}
		else if (ideas.length === 0)
		{
			return Math.min(window.innerHeight - 320, 200);
		}
		else
		{
			return window.innerHeight - 320;
		}
	}

	function renderTooltip ()
	{
		return ReactDOMServer.renderToString(
			<div className="ideas-tooltip">
				<span>
					{t("ideasDialog.ideaRemovedTooltip")}
				</span>
				<IconTwoArrows style={{marginLeft: 5}}/>
			</div>
		);
	}

	return (
		<Modal
			isOpen={ideasModalOpened}
			style={customStyles}
			onAfterOpen={() => false}
			onRequestClose={onClose}
		>

			<div className="modal">
				<div ref={contentEl} data-tip={renderTooltip()} data-place="left" className="ideas-tooltip-anchor"></div>
				<div className="modal-header">
					<div className="title">{t("ideasDialog.title")}</div>
				</div>

				<div className="modal-body" style={{height: getBodyHeight(), overflow: 'auto', paddingRight: 30}}>

					{
						loading && !initialLoaded && <Loader/>
					}

					{
						ideas.length !== 0 &&
						<InfiniteScroll pageStart={0} loadMore={() => loadIdeas()} hasMore={moreExists} loader={null}
										useWindow={false} initialLoad={false}>
							<div className="d-flex flex-wrap justify-content-between">
								{
									ideas.map(idea => (<IdeaItem key={idea.id} idea={idea}/>))
								}
							</div>
						</InfiniteScroll>
					}

					{
						!loading && ideas.length === 0 &&
						<p>{t("ideasDialog.empty")}.</p>
					}

				</div>

			</div>

		</Modal>
	);
}
