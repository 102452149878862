import {fabric} from "fabric";
import {snapToGrid} from "../draw";

fabric.RotatingLine = fabric.util.createClass(fabric.Line, {

    type : "rotatingLine",

    circle1 : null,

    circle2 : null,

    removeCirclesTimeout : 0,

    initialize: function (initialOptions)
    {
        this.callSuper('initialize', [initialOptions.x1, initialOptions.y1, initialOptions.x2, initialOptions.y2], {
                strokeWidth : 3,
                stroke : '#eaff00',
                strokeUniform : true,
                noScaleCache: false,
                selectable: true,
                hasControls: false,
                hasBorders: false,
                ...initialOptions
            });

        this.on('moving', this.onLineMove);
        this.on('removed', () => this.removeCircles(0));
        this.on('selected', this.checkSelection);
        this.on('deselected', () => this.removeCircles());
    },

    addCircles : function (lineId)
    {
        if (this.circle1 !== null || this.circle2 !== null)
        {
            return;
        }

        this.circle1 = new fabric.Circle({
            lineId,
            radius: 5,
            fill: '#f03678',
            left: this.x1,
            top: this.y1,
            hasControls: false,
            hasBorders: false,
            name: 'linePoint',
            padding: 10,
            originX: "center",
            originY: "center",
            excludeFromExport : true
        });

        this.circle2 = new fabric.Circle({
            lineId,
            radius: 5,
            fill: '#f03678',
            left: this.x2,
            top: this.y2,
            hasControls: false,
            hasBorders: false,
            name: 'linePoint',
            padding: 10,
            originX: "center",
            originY: "center",
            excludeFromExport : true
        });

        this.circle1.on('moving', this.onCircle1Move.bind(this));
        this.circle2.on('moving', this.onCircle2Move.bind(this));
        window.canvas.add(this.circle1, this.circle2);
    },

    onCircle1Move : function ()
    {
        if (!this.circle1 || !this.circle2)
        {
            return;
        }

        let x1 = snapToGrid(this.circle1.left),
            y1 = snapToGrid(this.circle1.top),
            x2 = snapToGrid(this.circle2.left),
            y2 = snapToGrid(this.circle2.top);

        this.set({x1: x1 - 1.5, y1 : y1 - 1.5, x2 : x2 - 1.5, y2 : y2 - 1.5, selectable : true});

        this.circle2.setCoords();
        this.setCoords();
        //window.canvas.renderAll();
        this.removeCircles();
    },

    onCircle2Move : function ()
    {
        if (!this.circle1 || !this.circle2)
        {
            return;
        }

        let x1 = snapToGrid(this.circle1.left),
            y1 = snapToGrid(this.circle1.top),
            x2 = snapToGrid(this.circle2.left),
            y2 = snapToGrid(this.circle2.top);

        this.set({x1: x1 - 1.5, y1 : y1 - 1.5, x2 : x2 - 1.5, y2 : y2 - 1.5, selectable : true});

        this.setCoords();
        this.circle1.setCoords();
        //window.canvas.renderAll();
        this.removeCircles();
    },

    removeCircles : function (timeout = 1000)
    {
        clearTimeout(this.removeCirclesTimeout);
        this.removeCirclesTimeout = setTimeout(() =>
        {
            window.canvas.remove(this.circle1, this.circle2);
            this.circle1 = null;
            this.circle2 = null;
        }, timeout);
    },

    onLineMove : function ()
    {
        if (!this.circle1 || !this.circle2)
        {
            return;
        }

        let x1, y1, x2, y2,
            left = snapToGrid(this.left),
            top = snapToGrid(this.top),
            lineWidth = this.width,
            lineHeight = this.height;

        if (this.circle1.top < this.circle2.top)
        {
            if (this.circle1.left < this.circle2.left)
            {
                x1 = left;
                y1 = top;
                x2 = left + lineWidth;
                y2 = top + lineHeight;
            }
            else
            {
                x1 = left + lineWidth;
                y1 = top;
                x2 = left;
                y2 = top + lineHeight;
            }
        }
        else
        {
            if (this.circle1.left < this.circle2.left)
            {
                x1 = left;
                y1 = top + lineHeight;
                x2 = left + lineWidth;
                y2 = top;
            }
            else
            {
                x1 = left + lineWidth;
                y1 = top + lineHeight;
                x2 = left;
                y2 = top;
            }
        }

        this.circle1.set({ left: x1, top: y1 });
        this.circle2.set({ left: x2, top: y2 });

        this.circle1.setCoords();
        this.circle2.setCoords();
        //window.canvas.renderAll();
    },

    checkSelection : function ()
    {
        let activeObject = window.canvas.getActiveObject();
        if (!activeObject)
        {
            return;
        }

        if (activeObject.type !== 'activeSelection')
        {
            clearTimeout(this.removeCirclesTimeout);
            this.addCircles(activeObject.id);
            this.onLineMove();
        }
        else
        {
            if (!activeObject._objects.includes(this.circle1))
            {
                activeObject.addWithUpdate(this.circle1);
            }

            if (!activeObject._objects.includes(this.circle2))
            {
                activeObject.addWithUpdate(this.circle2);
            }

            activeObject.set({hasControls : false});
        }
    },

    toObject: function() {
        return fabric.util.object.extend(this.callSuper('toObject'));
    },

    _render: function(ctx) {
        this.callSuper('_render', ctx);
    },
});

fabric.RotatingLine.fromObject = function (object, callback)
{
    return fabric.Object._fromObject('RotatingLine', object, callback);
};

export function initLine (x = null, y = null)
{
    let params = {};
    if (x !== null && y !== null)
    {
        params = {top : y, left : x};
    }
    else
    {
        params = {top : 100, left : 100};
    }

    return new fabric.RotatingLine({id : Math.random(), x1 : params.left, y1 : params.top, x2 : params.left + (x === null ? 100 : 0), y2 : params.top + (x === null ? 100 : 0)});
}
