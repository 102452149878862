import {fabric} from "fabric";

export const textBoxControlsVisibility = {
    bl : false,
    br : false,
    mb : false,
    ml : true,
    mr : true,
    mt : false,
    tl : false,
    tr : false,
    mtr : true
};

export function initText (x = null, y = null, text = 'wdyt?', options = {})
{
    let params = {};
    if (x !== null && y !== null)
    {
        params = {top : y, left : x, fontSize: 0};
    }
    else
    {
        params = {top : 100, left : 100, fontSize: 14};
    }

    let textbox = new fabric.Textbox(text, {
        ...params,
        strokeWidth : 0,
        padding: 0,
        opacity: 1,
        fill : '#eaff00',
        fontFamily: 'arial',
        strokeUniform : true,
        noScaleCache: false,
        hasControls: true,
        selectable : true,
        _controlsVisibility : textBoxControlsVisibility,
        ...options
    });

    return textbox;
}

export const fitTextBoxToContent = (canvas, textBox) =>
{
    let actualWidth = textBox.scaleX * textBox.width,
        largestLineWidth = canvas.getActiveObject().__lineWidths[canvas.getActiveObject().__lineWidths.length - 1] || textBox.width;

    if (textBox.width < largestLineWidth)
    {
        canvas.getActiveObject().set("width", (largestLineWidth + 5) * textBox.scaleX);
    }

    if ((textBox.left + actualWidth) >= canvas.width - 10)
    {
        textBox.set("width", canvas.width - textBox.left - 10);
    }
};
