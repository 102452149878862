import React, {useRef, useState} from 'react';
import Modal from 'react-modal';
import {shallowEqual, useSelector} from "react-redux";
import Loader from "../components/Loader";
import {setEditableIdea, setEditIdeaModalOpened, setIdeasUpdated} from "../ducks/ideas";
import {useTranslation} from "react-i18next";
import {setTextEditingMode} from "../ducks/main";
import * as firebase from "firebase";
import {removeIdea, saveIdea} from "../logic/ideas";

Modal.defaultStyles.overlay.backgroundColor = '#00000060';
Modal.setAppElement('#root');

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: 0,
		maxWidth: 600,
		width: '100%'
	},
	overlay: {
		zIndex: 10
	}
};

export default function EditIdeaModal ({})
{
	const editableIdea = useSelector(state => state.ideas.editableIdea, shallowEqual);

	const [ideaName, setIdeaName] = useState('');
	const [screenState, setScreenState] = useState('INITIAL');
	const nameInput = useRef();

	const {t} = useTranslation();

	async function updateIdea ()
	{
		setScreenState('LOADING');
		await saveIdea(editableIdea.id, {...editableIdea, name : ideaName});
		firebase.analytics().logEvent("updated_idea");
		setIdeasUpdated("EDITED");
		close();
	}

	async function deleteEditableIdea ()
	{
		setScreenState('LOADING');
		await removeIdea(editableIdea.id);
		firebase.analytics().logEvent("removed_idea");
		setIdeasUpdated("DELETED");
		close();
	}

	function afterOpen ()
	{
		setIdeaName(editableIdea.name);
		setTimeout(() => nameInput.current.select(), 0);
	}

	function close ()
	{
		setScreenState('INITIAL');
		setEditableIdea(null);
	}

	return (
	    <Modal
		    isOpen={editableIdea !== null}
		    style={customStyles}
		    onAfterOpen={afterOpen}
		    onRequestClose={close}
	    >

		    <div className="modal idea-modal">

				{
					screenState === 'LOADING' && <Loader/>
				}

			    <div className="modal-header">
				    <span className="title">{t("updateIdeaDialog.title")}</span>
			    </div>
			    <div className="modal-body d-block">
					<div className="d-flex">
						<div className="idea-image-wrapper w-50">
							{
								editableIdea &&
								<img className="idea-image" src={editableIdea.base64}/>
							}
						</div>
						<div className="pl-4 w-50">
							<div className="form-group">

								<label>
									<div className="mb-2">{t("updateIdeaDialog.name")}</div>
									<input ref={nameInput}
										   value={ideaName}
										   onChange={e => setIdeaName(e.target.value)}
										   onFocus={() => setTextEditingMode(editableIdea !== null)}
										   onBlur={() => setTextEditingMode(false)}
										   className="form-control w-100"/>
								</label>

							</div>
							{/*<div className="form-group">
								<label className="text-secondary">
									<input type="checkbox" disabled/> Make it private (premium)
								</label>
							</div>*/}
						</div>
					</div>
			    </div>

				<div className="modal-actions justify-content-between mt-3">

					<div>
						<span onClick={deleteEditableIdea} className="remove-idea hover-opacity">{t("remove").toLowerCase()}</span>
					</div>

					<div>
						<button disabled={screenState === 'LOADING'}
								onClick={close}
								className="button hover-opacity">
							{t(screenState === 'INITIAL' ? "cancel" : "close")}
						</button>

						{
							screenState !== 'SUCCESS' &&
							<button disabled={screenState === 'LOADING' || ideaName === ''}
									onClick={updateIdea}
									className="button hover-opacity primary">
								{t("update")}
							</button>
						}

					</div>

				</div>
		    </div>
	    </Modal>
    )
}
