import React from 'react';
import * as firebase from "firebase";
import {generateFunnyName} from "../logic/premades";
import {setUser} from "../ducks/main";
import {shallowEqual, useSelector} from "react-redux";
import {setForceSaveIdea} from "../ducks/ideas";
const googleAuth = require('../assets/googleAuth.png');

export default function AuthButton ({})
{
    const notLoggedForNewIdeaModalOpened = useSelector(state => state.ideas.notLoggedForNewIdeaModalOpened, shallowEqual);

    function auth ()
    {
        let provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
            let token = result.credential.accessToken,
                user = result.user,
                formattedUser = {
                    id : user.providerData[0].uid,
                    email : user.email,
                    name : user.displayName,
                    token : token,
                    funnyName : generateFunnyName()
                },
                matches = formattedUser.email.match(/@(.+)/);

            if (matches && matches[1] !== 'gmail.com')
            {
                formattedUser.company = matches[1];
            }

            setUser(formattedUser);

            // if user tried to save shared url as idea - we do it after successful sign in
            if (notLoggedForNewIdeaModalOpened)
            {
                setForceSaveIdea(true);
            }

        }).catch(function(error) {
            let errorCode = error.code,
                errorMessage = error.message,
                email = error.email,
                credential = error.credential;
            console.log(error);
        });
    }

    return (
        <img onClick={auth} height="40px" className="hover-opacity mt-4" src={googleAuth}/>
    );
}
