import * as React from "react";

function SvgIconReminder(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={512}
      height={512}
      {...props}
    >
      <linearGradient
        id="IconReminder_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={49.531}
        y1={13.25}
        x2={49.531}
        y2={97.063}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M32.9 35.2c.7 0 1.5-.3 2-.8l11.6-10.8v40.7c0 1.7 1.3 3 3 3s3-1.3 3-3V23.6l11.6 10.8c1.2 1.1 3.1 1.1 4.2-.1 1.1-1.2 1.1-3.1-.1-4.2L51.5 14.5c-.1-.1-.1-.1-.2-.1l-.2-.2-.2-.2c-.1 0-.2-.1-.2-.1-.1 0-.2-.1-.3-.1-.1 0-.1 0-.2-.1-.2 0-.4-.1-.6-.1-.2 0-.4 0-.6.1-.1 0-.1 0-.2.1-.1 0-.2.1-.3.1s-.2.1-.2.1c-.1 0-.2.1-.3.1-.1.1-.2.1-.2.2-.1 0-.1.1-.2.1L30.9 30c-1.2 1.1-1.3 3-.1 4.2.5.7 1.3 1 2.1 1z"
        fill="url(#IconReminder_svg__a)"
      />
      <linearGradient
        id="IconReminder_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={13.25}
        x2={49}
        y2={97.063}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M79.5 39.3h-16c-1.7 0-3 1.3-3 3s1.3 3 3 3h13v41h-55v-41h13c1.7 0 3-1.3 3-3s-1.3-3-3-3h-16c-1.7 0-3 1.3-3 3v47c0 1.7 1.3 3 3 3h61c1.7 0 3-1.3 3-3v-47c0-1.7-1.3-3-3-3z"
        fill="url(#IconReminder_svg__b)"
      />
    </svg>
  );
}

export default SvgIconReminder;
