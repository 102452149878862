import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import './logic/firebase-services';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import {configureStore} from "./store";
import { PersistGate } from 'redux-persist/integration/react'
import Loader from "./components/Loader";
import ThemeManager from "./theme-manager";

import "./i18n";

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const storageData = configureStore();

ReactDOM.render(
    <Provider store={storageData.store}>
        <PersistGate loading={<Loader/>} persistor={storageData.persistor}>
            <ThemeManager>
                <App />
            </ThemeManager>
        </PersistGate>
    </Provider>, document.getElementById('root'));

serviceWorker.register();
