import React from 'react';


export default function NotFound ({})
{
    return <div className="not-found">
        not found :(<br/>
        <a href={window.location.origin}>Create new document</a>
    </div>;
}
