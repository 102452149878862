import React, {useState} from 'react';
import {IconRoundCorners} from "../icons";
import {MenuItem} from "react-contextmenu";
import {shallowEqual, useSelector} from "react-redux";
import AutosizeInput from "react-input-autosize";
import {setTextEditingMode} from "../../ducks/main";
import {useTranslation} from "react-i18next";

export default function CanvasContextMenuCornersRadius ({setCornersRadius})
{
	const activeObject = useSelector(state => state.canvas.activeObject, shallowEqual);

	const [inputEl, setInputEl] = useState(null);
	const {t} = useTranslation();

	function getCornersRadius ()
	{
		return activeObject.cornersRounded ? activeObject.rx : 0;
	}

	return (
		<MenuItem className={`context-menu-item corners-radius`}>
			<div>
				<IconRoundCorners width={20} height={20}/>
				<span>{t("contextMenu.cornersRadius")}</span>
			</div>
			<div onClick={e => e.stopPropagation()} onContextMenu={e => e.stopPropagation()} className="d-flex input-wrapper">
				<button onClick={() => setCornersRadius((getCornersRadius() || 0) - 1)} type="button">-</button>
				<AutosizeInput
					inputRef={setInputEl}
					type="number"
					step="1"
					min="0"
					max="99"
					placeholder="0"
					onClick={() => inputEl.select()}
					value={getCornersRadius()}
					onFocus={() => setTextEditingMode(true)}
					onBlur={() => setTextEditingMode(false)}
					onChange={e => setCornersRadius(e.target.value.slice(0, 2).replace('-', ''))}
				/>
				<button onClick={() => setCornersRadius((getCornersRadius() || 0) + 1)} type="button">+</button>
			</div>
		</MenuItem>
	);
}
