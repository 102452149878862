import React, {useCallback, useEffect} from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {
	setTextEditingMode
} from "../ducks/main";
import {useDrawLogic} from "../logic/draw";
import {getKeyListener, saveCanvasData, useEventListener} from "../logic/share";
import {useTheme} from "../theme-manager";
import {CanvasContextMenu} from "../components";
import {useCanvas} from "../logic/canvas";
import {useCopyPasteLogic} from "../logic/copy";
import {useUpDown} from "../logic/updown";
import {useZoomLogic} from "../logic/zoom";
import {useArrowsMove} from "../logic/arrowsMove";
import {useAligningLogic} from "../logic/aligning";
import {useTranslation} from "react-i18next";

export default function Canvas ({savedData})
{
	const { theme } = useTheme();

    const user = useSelector(state => state.main.user, shallowEqual);
	const textEditingMode = useSelector(state => state.main.textEditingMode, shallowEqual);

    const mode = useSelector(state => state.canvas.mode, shallowEqual);
    const canvas = useSelector(state => state.canvas.canvas, shallowEqual);
    const template = useSelector(state => state.canvas.template, shallowEqual);

    const {onMouseDown, onMouseMove, onMouseUp} = useDrawLogic();
    const {onMouseDownZoom, onMouseMoveZoom, onMouseUpZoom, onZoom} = useZoomLogic();
    const {onCut, onCopy, onPaste} = useCopyPasteLogic();
    const {moveItemUp, moveItemDown} = useUpDown();
    const {moveArrowUp, moveArrowDown, moveArrowRight, moveArrowLeft} = useArrowsMove();

	const {initCanvas, canvasModifiedCallback, onDeletePress, onSelectionChange,
		oMouseHover, onUngroup, onGroup, onCreatePremade, onContextMenuRightClick,
		onTextChange, canvasRightClicked, toggleFill, toggleColorInvert, setCornersRadius, setTextSize,
		onRedo, onUndo, onSelectAll, onChangeStokeWidth, setSelectedTextStyle, setDimensions, onObjectMove, onMouseUpObjectMove} = useCanvas();

	const {onObjectMoveAligning, onMouseDownAligning, onBeforeRenderAligning, onAfterRenderAligning,
		addGuideline, onMouseDownGuidelines, removeAllGuidelines, removeSelectedGuideline, toggleGuidelinesVisibility} = useAligningLogic();

	const {t} = useTranslation();

	useEffect(() =>
	{
		initCanvas(savedData);
	}, []);

	useEffect(() =>
    {
        canvasModifiedCallback();
    }, [template]);

	const onEditingModeChange = useCallback((flag) =>
	{
		setTextEditingMode(flag);
	}, [canvas]);

	useEffect(() => {

        if (canvas)
        {
			canvas.on({'mouse:down': (e) => {
					if (e.button === 1)
					{
						onMouseDown(e);
						onMouseDownZoom(e);
						onMouseDownAligning(e);
					}

					if (e.button === 3)
					{
						onMouseDownGuidelines(e);
					}
				}});
			canvas.on({'mouse:up': (e) => {
				if (e.button === 1)
				{
					onMouseUp(e);
					onMouseUpZoom(e);
					onMouseUpObjectMove(e);
				}
			}});
            canvas.on({'mouse:move': (e) => {onMouseMove(e); onMouseMoveZoom(e)}});
            canvas.on({'object:added' : canvasModifiedCallback});
            canvas.on({'object:removed' : canvasModifiedCallback});
            canvas.on({'object:modified' : canvasModifiedCallback});
            canvas.on({'selection:created' : onSelectionChange});
            canvas.on({'selection:updated' : onSelectionChange});
            canvas.on({'selection:cleared' : onSelectionChange});
            canvas.on({'mouse:over' : e => oMouseHover(e, true)});
            canvas.on({'mouse:out' : e => oMouseHover(e, false)});
            canvas.on({'text:editing:entered' : e => onEditingModeChange(true)});
            canvas.on({'text:editing:exited' : e => onEditingModeChange(false)});
            canvas.on({'text:changed' : onTextChange});
            canvas.on({'mouse:wheel' : e => {onZoom(e);}});
            canvas.on({'object:moving' : (e) => {onObjectMove(e); onObjectMoveAligning(e)}});
            canvas.on({'before:render' : (e) => {onBeforeRenderAligning(e)}});
            canvas.on({'after:render' : (e) => {onAfterRenderAligning(e)}});
            document.querySelector('.upper-canvas').addEventListener('contextmenu', onContextMenuRightClick);
        }

        return () => {

            if (canvas)
            {
                canvas.__eventListeners["mouse:move"] = [];
                canvas.__eventListeners["mouse:down"] = [];
                canvas.__eventListeners["mouse:up"] = [];
                canvas.__eventListeners["object:added"] = [];
                canvas.__eventListeners["object:removed"] = [];
                canvas.__eventListeners["object:modified"] = [];
                canvas.__eventListeners["selection:created"] = [];
                canvas.__eventListeners["selection:updated"] = [];
                canvas.__eventListeners["selection:cleared"] = [];
                canvas.__eventListeners["mouse:over"] = [];
                canvas.__eventListeners["mouse:out"] = [];
                canvas.__eventListeners["text:editing:entered"] = [];
                canvas.__eventListeners["text:editing:exited"] = [];
                canvas.__eventListeners["text:changed"] = [];
                canvas.__eventListeners["mouse:wheel"] = [];
                canvas.__eventListeners["object:moving"] = [];
                canvas.__eventListeners["before:render"] = [];
                canvas.__eventListeners["after:render"] = [];
				document.querySelector('.upper-canvas').removeEventListener('contextmenu', onContextMenuRightClick);
            }
        };
    }, [canvas, onMouseMove, mode, user, onMouseUpObjectMove, onAfterRenderAligning]);

	useEventListener('keydown', getKeyListener([8, 46], onDeletePress));
	useEventListener('keydown', getKeyListener([80], onCreatePremade, true, true));
	useEventListener('keydown', getKeyListener([71], onGroup, true, true));
	useEventListener('keydown', getKeyListener([71], onUngroup, true, true, true));

	useEventListener('keydown', getKeyListener([70], () => toggleFill(true), true, true));
	useEventListener('keydown', getKeyListener([70], () => toggleFill(false), true, true, true));

	useEventListener('keydown', getKeyListener([67], () => toggleColorInvert(true), true, true));
	useEventListener('keydown', getKeyListener([67], () => toggleColorInvert(false), true, true, true));

	useEventListener('keydown', getKeyListener([67], onCopy, false, true));
	useEventListener('keydown', getKeyListener([86], onPaste, false, true));
	useEventListener('keydown', getKeyListener([88], onCut, false, true));

	useEventListener('keydown', getKeyListener([38], moveItemUp, false, true));
	useEventListener('keydown', getKeyListener([40], moveItemDown, false, true));

	useEventListener('keydown', getKeyListener([38], () => moveArrowUp(false)));
	useEventListener('keydown', getKeyListener([40], () => moveArrowDown(false)));
	useEventListener('keydown', getKeyListener([37], () => moveArrowLeft(false)));
	useEventListener('keydown', getKeyListener([39], () => moveArrowRight(false)));
	useEventListener('keydown', getKeyListener([38], () => moveArrowUp(true), false, false, true));
	useEventListener('keydown', getKeyListener([40], () => moveArrowDown(true), false, false, true));
	useEventListener('keydown', getKeyListener([37], () => moveArrowLeft(true), false, false, true));
	useEventListener('keydown', getKeyListener([39], () => moveArrowRight(true), false, false, true));


	useEventListener('keydown', getKeyListener([90], onUndo, false, true));
	useEventListener('keydown', getKeyListener([90], onRedo, false, true, true));
	useEventListener('keydown', getKeyListener([65], onSelectAll, false, true));

	useEventListener('keydown', getKeyListener([66], () => setSelectedTextStyle('fontWeight', 'bold'), false, true));
	useEventListener('keydown', getKeyListener([85], () => setSelectedTextStyle('underline', true), false, true));
	useEventListener('keydown', getKeyListener([73], () => setSelectedTextStyle('fontStyle', 'italic'), false, true));

	useEventListener('keydown', getKeyListener([72], toggleGuidelinesVisibility, true, true, false));

	return <div className="canvas-container" style={{backgroundColor: theme.primaryBackgroundColor}}>

	    <CanvasContextMenu t={t}
						   onUngroup={onUngroup}
						   onGroup={onGroup}
						   onCreatePremade={onCreatePremade}
						   canvasRightClicked={canvasRightClicked}
						   toggleFill={toggleFill}
						   toggleColorInvert={toggleColorInvert}
						   setCornersRadius={setCornersRadius}
						   setTextSize={setTextSize}
						   setDimensions={setDimensions}
						   onChangeStokeWidth={onChangeStokeWidth}
						   moveItemUp={moveItemUp}
						   moveItemDown={moveItemDown}
						   addGuideline={addGuideline}
						   removeAllGuidelines={removeAllGuidelines}
						   removeSelectedGuideline={removeSelectedGuideline}
						   toggleGuidelinesVisibility={toggleGuidelinesVisibility}
		/>

    </div>
}
