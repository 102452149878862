import {shallowEqual, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";

import {
    setActiveObject,
    setCanvas,
    setChangesExists,
    setLastActiveObjectChangeTime,
    setSavedLocalData, setShowZoomIndicator
} from "../ducks/canvas";
import {fabric} from "fabric";
import {getObjectsCount} from "./grid";
import {initText} from "./shapes";
import {setFirstImpressionProcessed, setShareBoxOpened} from "../ducks/main";
import {groupObjects, objectsCanBeGroupped, objectsCanBeUngroupped, ungroupObjects} from "./groupping";
import {setAddPremadeModalOpened, setCreatedPremadeData} from "../ducks/premades";
import {exportPNG} from "./premades";
import {fitTextBoxToContent, textBoxControlsVisibility} from "./shapes/text";
import {mobilecheck} from "../helper";
import * as firebase from "firebase";
import './history';
import {useZoomLogic} from "./zoom";

fabric.Object.prototype.exportPNG = exportPNG;
fabric.Object.NUM_FRACTION_DIGITS = 5;

export const customProps = ['arrowLineTriangleId', 'id', 'filled', 'isSvg', 'colorInverted', 'cornersRounded', '_controlsVisibility'];

export function useCanvas ()
{
    const firstImpressionProcessed = useSelector(state => state.main.firstImpressionProcessed, shallowEqual);
    const shareId = useSelector(state => state.main.shareId, shallowEqual);
    const textEditingMode = useSelector(state => state.main.textEditingMode, shallowEqual);
    const user = useSelector(state => state.main.user, shallowEqual);

    const canvas = useSelector(state => state.canvas.canvas, shallowEqual);
    const template = useSelector(state => state.canvas.template, shallowEqual);
    const activeObject = useSelector(state => state.canvas.activeObject, shallowEqual);

    const addPremadeModalOpened = useSelector(state => state.premades.addPremadeModalOpened, shallowEqual);
    const openedPremadeData = useSelector(state => state.premades.openedPremadeData, shallowEqual);

    const [initiated, setInitiated] = useState(false);
    const [canvasRightClicked, setCanvasRightClicked] = useState(false);
    const [movableObject, setMovableObject] = useState(null);
    const {zoomToSeeAllObjects} = useZoomLogic();

    const initCanvas = (savedData) => {

        const canvas = new fabric.Canvas('canvas', {backgroundColor: '#000501', fireRightClick: true});
        setCanvas(canvas);
        window.canvas = canvas;

        if (!mobilecheck() && savedData !== null)
        {
            // savedData can be canvas JSON in localStorage or element from firestore with additional data (.data - canvas JSON)
            let objects = JSON.parse(savedData.data || savedData).objects;

            fabric.util.enlivenObjects(objects, function(objects)
            {
                objects.forEach((object, i) =>
                {
                    object.set({opacity : 0});
                    if (i === 0)
                    {
                        setTimeout(() => object.animate('opacity', '1', {duration: 300, onChange: canvas.renderAll.bind(canvas)}), 400);
                    }
                    else
                    {
                        setTimeout(() => object.animate('opacity', '1', {duration: 300}), 400);
                    }
                });
                canvas.add(...objects);
            });

            //canvas.loadFromJSON(savedData, canvas.renderAll.bind(canvas));
        }

        //initGrid(canvas);
        canvas.renderAll();

        canvas.preserveObjectStacking = true;
        fabric.Object.prototype.cornerStyle = 'square';
        fabric.Object.prototype.transparentCorners = false;
        fabric.Object.prototype.cornerSize = 7;
        fabric.Object.prototype.hasBorders = 'false';
        fabric.Object.prototype.cornerColor = '#f03678';
        fabric.Object.prototype.borderColor = '#f03678';

        if (mobilecheck())
        {
            processMobileImpression(canvas, savedData);
        }
        else if (!firstImpressionProcessed)
        {
            processFirstImpression(canvas);
            zoomToSeeAllObjects(canvas);
        }
        else
        {
            setInitiated(true);
            setShowZoomIndicator(true);
            zoomToSeeAllObjects(canvas);
        }

        setTimeout(() => {
            canvas._historyInit();
        }, 800);

    };

    const processFirstImpression = useCallback((canvas) => {
       /*
        let width = 550,
            left = Math.round(window.innerWidth / 2 - width / 2),
            top = window.innerHeight / 2 - 50;

        let text = initText(300, 300, 'The fastest way from idea to brainstorm', {
            fontSize: 30,
            left,
            top,
            width,
            fontFamily : 'Nunito',
            opacity : 0
        });

        canvas.add(text);
        canvas.renderAll();
        setTimeout(() => text.animate('opacity', '1', {duration: 300, onChange: canvas.renderAll.bind(canvas)}), 400);*/

        setTimeout(() => {
            setFirstImpressionProcessed(true);
            setSavedLocalData(JSON.stringify(canvas.toJSON(customProps)));
        }, 3000);

        setInitiated(true);

    }, []);

    const processMobileImpression = useCallback((canvas, savedData) => {

        let renderPremadeOrSharedData = (base64) => {
                let div = document.createElement('div');
                div.classList.add('premade-image-wrapper', 'premade-mobile-preview');
                div.innerHTML = `<img class="premade-image" src="${base64}">`;
                document.body.append(div);
                document.querySelector('#big-ideas').style.paddingTop = '170px';
            };

        if (openedPremadeData)
        {
            renderPremadeOrSharedData(openedPremadeData.base64);
        }
        // if user opened shared url (savedData - shared url data)
        else if (savedData && savedData.data)
        {
            renderPremadeOrSharedData(savedData.base64);
        }

        canvas.renderAll();
    }, []);

    const canvasModifiedCallback = useCallback(() =>
    {
        if (initiated)
        {
            setChangesExists(getObjectsCount(canvas) !== 0);
            window.history.pushState({}, null, window.location.origin);
            canvas.clearContext(canvas.contextTop);
            setShareBoxOpened(false);

            canvas.getObjects().forEach(object =>
            {
                if (!object.filled && object.type !== 'textbox' && !object.isSvg && object.name !== 'arrowLineTriangle')
                {
                    // remove hovered state
                    if (['#f0367810', 'transparent'].indexOf(object.fill) !== -1)
                    {
                        object.set('fill', 'transparent');
                    }
                }

                // @TODO WTF? very strange bug that after changing selected text style, it becomes unselectable after load and also another props disappear
                /*if (object.type === 'textbox')
                {
                    object.selectable = true;
                    object.setControlsVisibility(textBoxControlsVisibility);
                }*/
            });

            canvas.renderAll();
            if (!canvas.drawMode)
            {
                canvas._historySaveAction();
            }
        }

        if (shareId !== null || canvas === null)
        {
            return;
        }

        setSavedLocalData(JSON.stringify(canvas.toJSON(customProps)));
    }, [initiated, canvas, shareId]);

    const onDeletePress = useCallback(() => {

        let selection = canvas.getActiveObject();
        if (!selection || textEditingMode || addPremadeModalOpened)
        {
            return;
        }

        canvas.discardActiveObject();

        if (selection.type === 'activeSelection')
        {
            selection.forEachObject(function(element)
            {
                canvas.remove(element);
            });
        }
        else
        {
            canvas.remove(selection);
        }
        canvas.requestRenderAll();

        template && canvas.remove(template);
        setActiveObject(null);
    }, [canvas, template, textEditingMode, addPremadeModalOpened]);

    const onSelectionChange = useCallback(() =>
    {
        setActiveObject(canvas.getActiveObject());
    }, [canvas, user]);

    const onObjectMove = useCallback((e) =>
    {
        let withAlt = e.e.altKey === true;
        if (!withAlt || e.target.lockMovementX || e.target.lockMovementY)
        {
            return;
        }

        let movX = e.e.movementX || e.e.mozMovementX || e.e.webkitMovementX || 0;
        let movY = e.e.movementY || e.e.mozMovementY || e.e.webkitMovementY || 0;

        if (movX)
        {
            e.target.set({lockMovementY : true});
        }
        else if (movY)
        {
            e.target.set({lockMovementX : true});
        }

        setMovableObject(e.target);
    }, [movableObject]);

    const onMouseUpObjectMove = useCallback(() =>
    {
        if (!movableObject)
        {
            return;
        }

        movableObject.set({lockMovementX : false, lockMovementY : false});
        setMovableObject(null);
    }, [movableObject]);

    const onGroup = useCallback(() =>
    {
        let activeObj = canvas.getActiveObject();
        if (!objectsCanBeGroupped(activeObj))
        {
            return;
        }

        let group = groupObjects(activeObj);
        setActiveObject(group);
        firebase.analytics().logEvent(`shape_group`);
    }, [canvas]);

    const onCreatePremade = useCallback(() =>
    {
        let activeObj = canvas.getActiveObject();
        if (!activeObj)
        {
            return;
        }

        if (activeObj._objects)
        {
            activeObj._objects.filter(object => object.name === "linePoint").forEach(linePoint => activeObj.removeWithUpdate(linePoint));
        }

        setAddPremadeModalOpened(true);

        groupObjects(activeObj);
        let group = canvas.getActiveObject();
        group.exportPNG(base64 => setCreatedPremadeData({data : JSON.stringify(group.toJSON(customProps)), base64}));
        firebase.analytics().logEvent(`shape_create_premade`);
        //setTimeout(() => ungroupObjects(canvas, group), 500);

        /*activeObj.clone(function(clone) {
            canvas.add(clone);
            let activeGroup = clone.toGroup ? clone.toGroup() : clone;
            activeGroup.exportPNG(base64 => setPremadedata({data : JSON.stringify(activeGroup), base64}));
        });*/
        //let activeGroup = clone.toGroup ? clone.toGroup() : clone;
        //clone.exportPNG(base64 => setPremadedata({data : JSON.stringify(clone), base64}));


    }, [canvas]);

    const onUngroup = useCallback(() =>
    {
        let activeObj = canvas.getActiveObject();
        if (!objectsCanBeUngroupped(activeObj))
        {
            return;
        }

        ungroupObjects(canvas, activeObj);
        setActiveObject(null);
        firebase.analytics().logEvent(`shape_ungroup`);

    }, [canvas]);

    const oMouseHover = useCallback((e, hovered) =>
    {
        let hoveredObject = e.target;
        if (!hoveredObject || hoveredObject.name === 'grid' || hoveredObject.name === 'arrowLineTriangle' || hoveredObject.name === 'linePoint' || hoveredObject.isSvg)
        {
            return;
        }

        let func = object =>
        {
            if (object.type === 'textbox' || object.name === 'arrowLineTriangle' || object.name === 'linePoint' || object.filled)
            {
                return;
            }

            if (['#f0367810', 'transparent'].indexOf(object.fill) !== -1)
            {
                object.set('fill', hovered ? '#f0367810' : 'transparent');
            }
        };

        if (e.target._objects)
        {
            hoveredObject._objects.forEach(func);
        }
        else
        {
            func(hoveredObject);
        }

        if (hovered)
        {
            hoveredObject._renderControls(canvas.contextTop, {});
        }
        else
        {
            canvas.clearContext(canvas.contextTop);
        }

        canvas.renderAll();
    }, [canvas]);

    const onContextMenuRightClick = useCallback((e) =>
    {
        let x = e.offsetX,
            y = e.offsetY,
            canvasRightClicked = true;

        for (let i = 0, max = canvas._objects.length; i < max; i++)
        {
            let object = canvas._objects[i];
            if (object.name === "grid")
            {
                continue;
            }

            let bounds = object.getBoundingRect(false, false);

            if (object.group)
            {
                bounds = object.group.getBoundingRect(false, false);
            }

            if (x >= bounds.left && x <= (bounds.left + bounds.width))
            {
                if (y >= bounds.top && y <= (bounds.top + bounds.height))
                {
                    if (!canvas.getActiveObject())
                    {
                        canvas.setActiveObject(object);
                        canvas.renderAll();
                    }

                    canvasRightClicked = false;
                    break;
                }
            }
        }

        // if we are here it means that only grid elements were clicked
        setCanvasRightClicked(canvasRightClicked);

        //e.stopPropagation();
        //e.preventDefault();
    }, [canvas]);

    const onTextChange = useCallback((e) =>
    {
        fitTextBoxToContent(canvas, e.target);
        canvas.renderAll();
    }, [canvas]);

    const toggleFill = (forceFlag) =>
    {
        if (!activeObject.filled || forceFlag)
        {
            firebase.analytics().logEvent(`shape_add_fill`);
            activeObject.set('fill', activeObject.colorInverted ? '#000501' : '#eaff03');
            activeObject.set('filled', true);
        }
        else
        {
            firebase.analytics().logEvent(`shape_remove_fill`);
            activeObject.set('fill', 'transparent');
            activeObject.set('filled', false);
        }
        setLastActiveObjectChangeTime(Date.now());
        canvasModifiedCallback();
        canvas.renderAll();
    };

    const toggleColorInvert = (forceFlag) =>
    {
        const isTextbox = activeObject.type === 'textbox';
        if (!activeObject.colorInverted || forceFlag)
        {
            activeObject.set(isTextbox ? 'fill' : 'stroke', '#000501');
            activeObject.set('colorInverted', true);
            if (activeObject.filled)
            {
                activeObject.set('fill', '#000501');
            }
        }
        else
        {
            activeObject.set(isTextbox ? 'fill' : 'stroke', '#eaff00');
            activeObject.set('colorInverted', false);
            if (activeObject.filled)
            {
                activeObject.set('fill', '#eaff03');
            }
        }

        firebase.analytics().logEvent(`shape_invert_color`);
        setLastActiveObjectChangeTime(Date.now());
        canvasModifiedCallback();
        canvas.renderAll();
    };

    const setCornersRadius = (cornersRadius) =>
    {
        if (cornersRadius !== '')
        {
            cornersRadius = Math.min(Math.max(cornersRadius, 0), 99);
        }

        firebase.analytics().logEvent(`shape_round_corners`);
        activeObject.set({'rx' : cornersRadius, 'ry' : cornersRadius});
        activeObject.set('cornersRounded', true);
        setLastActiveObjectChangeTime(Date.now());
        canvasModifiedCallback();
        canvas.renderAll();
    };

    const setDimensions = (dimensions) =>
    {
        dimensions.width = +dimensions.width;
        dimensions.height = +dimensions.height;

        if (activeObject._objects !== undefined || activeObject.type === 'circle')
        {
            let scaleX = Math.round(dimensions.width / (activeObject.type === 'circle' ? activeObject.radius : activeObject.width) * 1000000) / 1000000;
            let scaleY = Math.round(dimensions.height / (activeObject.type === 'circle' ? activeObject.radius : activeObject.height) * 1000000) / 1000000;
            activeObject.set({scaleX, scaleY});
        }
        else
        {
            activeObject.set(dimensions);
        }

        firebase.analytics().logEvent(`shape_change_dimensions`);
        activeObject.setCoords();
        setLastActiveObjectChangeTime(Date.now());
        canvasModifiedCallback();
        canvas.renderAll();
    };

    const setTextSize = (textSize) =>
    {
        if (textSize !== '')
        {
            textSize = Math.min(Math.max(textSize, 1), 999);
        }

        firebase.analytics().logEvent(`textbox_font_size_change`);
        activeObject.set({'fontSize' : textSize});
        setLastActiveObjectChangeTime(Date.now());
        canvasModifiedCallback();
        canvas.renderAll();
    };

    const setSelectedTextStyle = (propName, propValue) =>
    {
        if (!activeObject)
        {
            return;
        }

        let start = activeObject.selectionStart,
            end = activeObject.selectionEnd - 1,
            styleApplied = Object.values(activeObject.styles).find(styleItem => Object.keys(styleItem)
                .find(charIndex => (charIndex >= start && charIndex <= end && styleItem[charIndex][propName] === propValue)) !== undefined) !== undefined;

        activeObject.setSelectionStyles({ [propName] : !styleApplied ? propValue : ''}, activeObject.selectionStart, activeObject.selectionEnd);
        firebase.analytics().logEvent(`textbox_set_style_${propName}`);
        setLastActiveObjectChangeTime(Date.now());
        canvasModifiedCallback();
        canvas.renderAll();
    };

    const onRedo = useCallback((e) =>
    {
        if (textEditingMode)
        {
            return;
        }

        canvas.redo();
    }, [canvas, textEditingMode]);

    const onUndo = useCallback((e) =>
    {
        if (textEditingMode)
        {
            return;
        }

        canvas.undo();
    }, [canvas, textEditingMode]);

    const onSelectAll = useCallback((e) =>
    {
        if (textEditingMode)
        {
            return;
        }

        e.preventDefault();
        canvas.discardActiveObject();
        let sel = new fabric.ActiveSelection(canvas.getObjects(), {
            canvas: canvas,
        });

        canvas.setActiveObject(sel);
        canvas.requestRenderAll();

    }, [canvas, textEditingMode]);

    const onChangeStokeWidth = (strokeWidth, button) =>
    {
        if (!activeObject)
        {
            return;
        }

        activeObject.set({strokeWidth});
        let buttons = document.querySelectorAll('.line-width button');
        Array.prototype.forEach.call(buttons, button => button.classList.remove('selected'));
        button.classList.add('selected');

        canvas.requestRenderAll();
    };

    return {initCanvas, canvasModifiedCallback, onDeletePress, onSelectionChange, onTextChange, onContextMenuRightClick,
        oMouseHover, onUngroup, onGroup, onCreatePremade, canvasRightClicked, toggleFill, toggleColorInvert, setCornersRadius,
        setTextSize, setSelectedTextStyle, onRedo, onUndo, onSelectAll, onChangeStokeWidth, setDimensions, onObjectMove, onMouseUpObjectMove};
}

export const analyzeObject = (activeObj) =>
{
    let premadeCanBeCreated = false,
        groupCanBeCreated = false,
        isGroupSelected = false;

    if (activeObj)
    {
        if (activeObj.type === 'group')
        {
            isGroupSelected = true;
            premadeCanBeCreated = true;
        }
        else if (activeObj._objects !== undefined)
        {
            premadeCanBeCreated = true;
            groupCanBeCreated = true;
        }
    }

    return {
        isGroupSelected,
        premadeCanBeCreated,
        groupCanBeCreated
    };
};
