import * as React from "react";

function SvgIconArrow(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <linearGradient
        id="IconArrow_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={256}
        y1={479.608}
        x2={256}
        y2={32.392}
        gradientTransform="rotate(-134.999 256 256.002)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M188.8 157c-1.5 5.2 0 11.1 4.1 15.1l62.4 62.4L97.9 391.9c-6.3 6.3-6.3 15.9 0 22.2 6.3 6.3 15.9 6.3 22.2 0l157.4-157.4 62.4 62.4c4.1 4.1 10 5.5 15.1 4.1 5.2-1.5 9.6-5.9 11.1-11.1L418.2 113c1.5-5.2 0-11.1-4.1-15.1-4.1-4.1-10-5.5-15.1-4.1l-199.1 52.1c-5.2 1.5-9.7 5.9-11.1 11.1zm192.4-26.2l-38.4 147-108.2-108.2 146.6-38.8z"
        fill="url(#IconArrow_svg__a)"
      />
    </svg>
  );
}

export default SvgIconArrow;
