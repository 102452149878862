import * as React from "react";

function SvgIconEmail(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={512}
      height={512}
      {...props}
    >
      <linearGradient
        id="IconEmail_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={96.25}
        x2={49}
        y2={4.193}
        gradientTransform="matrix(1 0 0 -1 0 104)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M89 43.8c0-.9-.4-1.7-1.1-2.3l-9.6-8V22.2c0-1.7-1.3-3-3-3H60.9l-10-8.3c-1.1-.9-2.7-.9-3.8 0L37 19.2H22.7c-1.7 0-3 1.3-3 3v11.3l-9.6 8c-.7.6-1.1 1.4-1.1 2.3v49c0 1.7 1.3 3 3 3h74c1.7 0 3-1.3 3-3v-49zm-7.8-.1l-2.9 2.1v-4.5l2.9 2.4zM49 17.1l2.5 2.1h-5.1l2.6-2.1zm23.3 8.1v25.1L49 67.7 25.7 50.3V25.2h46.6zM19.7 41.3v4.5l-2.8-2.1 2.8-2.4zM15 89.8v-40l32.2 24c.5.4 1.2.6 1.8.6s1.3-.2 1.8-.6l32.2-24v40H15z"
        fill="url(#IconEmail_svg__a)"
      />
      <linearGradient
        id="IconEmail_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={49}
        y1={96.25}
        x2={49}
        y2={4.193}
        gradientTransform="matrix(1 0 0 -1 0 104)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M40.1 51.6l8.9-8.1 8.9 8.1c.6.5 1.3.8 2 .8.8 0 1.6-.3 2.2-1 1.1-1.2 1-3.1-.2-4.2L51 37.3c-1.1-1-2.9-1-4 0l-10.9 9.9c-1.2 1.1-1.3 3-.2 4.2 1.1 1.2 3 1.3 4.2.2z"
        fill="url(#IconEmail_svg__b)"
      />
    </svg>
  );
}

export default SvgIconEmail;
