import React, {useCallback, useEffect, useRef, useState} from 'react';
import {shallowEqual, useSelector} from "react-redux";

import {IconLink, IconClear, IconCopy, IconTwoArrows, IconIdeas, IconAddIdea} from "../components/icons";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactTooltip from "react-tooltip";
import {markCanvasDataAsIdea, saveCanvasData, useShareUrl} from "../logic/share";
import {setShareBoxOpened, setShareId} from "../ducks/main";
import {updatePremadesScore} from "../logic/premades";
import ReactDOMServer from "react-dom/server";
import {setAddedPremadesIds} from "../ducks/premades";
import * as firebase from "firebase";
import {useTranslation} from "react-i18next";
import {setForceSaveIdea, setNotLoggedForNewIdeaModalOpened} from "../ducks/ideas";

export default function Share ({})
{
    const shareId = useSelector(state => state.main.shareId, shallowEqual);
    const user = useSelector(state => state.main.user, shallowEqual);
    const shareBoxOpened = useSelector(state => state.main.shareBoxOpened, shallowEqual);

    const canvas = useSelector(state => state.canvas.canvas, shallowEqual);
    const changesExists = useSelector(state => state.canvas.changesExists, shallowEqual);

	const addedPremadesIds = useSelector(state => state.premades.addedPremadesIds, shallowEqual);

    const forceSaveIdea = useSelector(state => state.ideas.forceSaveIdea, shallowEqual);

    const shareUrl = useShareUrl();

    const [screenState, setScreenState] = useState('INITIAL');
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(false);
    const [savedData, setSavedData] = useState(null);
    const [dataMarkedAsIdea, setDataMarkedAsIdea] = useState(null);

    const shareBoxEl = useRef();
    const shareButtonEl = useRef();
    const textEl = useRef();

    const select = useCallback(() => textEl.current.select(), [textEl]);

    const {t} = useTranslation();

    useEffect(() =>
    {
        ReactTooltip.show(shareButtonEl.current);
    }, []);

    useEffect(() =>
    {
        if (forceSaveIdea)
        {
            markSavedDataAsIdea();
            setForceSaveIdea(false);
        }
    }, [forceSaveIdea]);

    async function openShareBox ()
    {
        setLoading(true);

        if (true || shareId === null)
        {
            const [savedData, encryptedId] = await saveCanvasData(canvas);
            if (encryptedId === null)
            {
                alert('Something went wrong. Please contact us! ');
                return;
            }

            setSavedData(savedData);
            window.history.pushState({}, null, '/share/' + encodeURIComponent(encryptedId));
            updatePremadesScore(addedPremadesIds, user);
	        setAddedPremadesIds([]);
            setShareId(encryptedId);
            setShareBoxOpened(true);

            firebase.analytics().logEvent("generate_share_url");

            setTimeout(() =>
            {
                select();
                document.execCommand('copy');
                onCopy();
            }, 0);

        }
        /*else
        {
            const saved = await updateCanvasData(shareId, canvas);
            if (!saved)
            {
                alert('Something went wrong. Please contact us! ');
                return;
            }
        }*/

        setLoading(false);
    }

    function onCopy ()
    {
        setDataMarkedAsIdea(false);
        setCopied(true);
        setTimeout(() =>
        {
            ReactTooltip.show(shareBoxEl.current);
        }, 0);

        setTimeout(() => {
            //ReactTooltip.hide(shareBoxEl.current);
            setCopied(false);
        }, 1000);
    }

    function renderTooltip ()
    {
        if (dataMarkedAsIdea)
        {
            return ReactDOMServer.renderToString(
                <div className="share-tooltip">
                    <span>{t("share.afterMarketAsIdeaTooltip")}</span>
                    <IconTwoArrows style={{marginLeft: 5}}/>
                </div>
            );
        }
        else if (shareBoxOpened)
        {
            return ReactDOMServer.renderToString(
                <div className="share-tooltip">
                    <span>{t("share.beforeCopyTooltip")}</span>
                    <IconTwoArrows style={{marginLeft: 5}}/>
                </div>
            );
        }
       /* else if (copied)
        {
            return ReactDOMServer.renderToString(
                <div className="share-tooltip">
                    <span>{t("share.afterCopyTooltip")}</span>
                    <IconTwoArrows style={{marginLeft: 5}}/>
                </div>
            );
        }*/
    }

    async function markSavedDataAsIdea ()
    {
        if (!user)
        {
            setNotLoggedForNewIdeaModalOpened(true);
            return;
        }

        setScreenState('LOADING');
        await markCanvasDataAsIdea(savedData, user);
        firebase.analytics().logEvent("mark_idea");
        setScreenState('INITIAL');

        setDataMarkedAsIdea(true);
        setTimeout(() =>
        {
            ReactTooltip.show(shareBoxEl.current);
        }, 0);

    }

    if (!changesExists)
    {
        return null;
    }

    return (
        <div className="share-container">
            {
                !shareBoxOpened &&
                <button ref={shareButtonEl} data-tip={renderTooltip()} onClick={openShareBox} className="hover-opacity round-button">
                    {
                        !loading && <IconLink width={40} height={40}/>
                    }
                    {
                        loading &&
                        <div className="loading-animation">
                            <div></div>
                        </div>
                    }

                </button>
            }

            {
                shareBoxOpened &&
                <div ref={shareBoxEl} data-tip={renderTooltip()} data-place="left" className="share-box d-flex justify-content-between">

                    <input ref={textEl} value={loading ? 'Wait...' : shareUrl} onChange={() => false} className="w-100"/>

                    <div className="d-flex">
                        <React.Fragment>
                            {
                                screenState === 'INITIAL' && !dataMarkedAsIdea &&
                                <IconAddIdea className="hover-opacity ml-3 fill-black" onClick={() => markSavedDataAsIdea()}  width={16} height={16}/>
                            }

                            {
                                screenState === 'LOADING' &&
                                <div className="loading-animation">
                                    <div></div>
                                </div>
                            }
                        </React.Fragment>

                        <CopyToClipboard text={shareUrl} onCopy={onCopy}>
                            <IconCopy className="hover-opacity ml-2 mr-2 fill-black" width={16} height={16}/>
                        </CopyToClipboard>

                        <IconClear className="hover-opacity" onClick={() => setShareBoxOpened(false)}/>
                    </div>

                </div>
            }

        </div>
    )
}
