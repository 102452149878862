import React, {useRef} from 'react';
import {IconAdd, IconLink, IconUser} from "./icons";
import {usePremades} from "../logic/premades";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CryptoJS from "crypto-js/crypto-js";
import {KEY} from "../logic/share";
import * as firebase from "firebase";
import ReactDOMServer from "react-dom/server";
import IconTwoArrows from "./icons/IconTwoArrows";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";
import {LazyLoadImage} from "react-lazy-load-image-component";
import {Loader} from "./index";

export default function PremadeItem ({premade})
{
    const {addPremadeOnCanvas} = usePremades();
    const copyUrlEl = useRef();
    const {t} = useTranslation();

    function getPremadeShareURL (premade)
    {
        return window.location.origin + '/premade/' + CryptoJS.AES.encrypt(premade.id, KEY).toString();
    }

    function addPremade ()
    {
        firebase.analytics().logEvent(`add_premade_on_canvas`);
        addPremadeOnCanvas(premade);
    }

    function copyPremadeUrl ()
    {
        firebase.analytics().logEvent(`copy_premade_url`);
        ReactTooltip.rebuild();
        console.log(copyUrlEl.current);
        setTimeout(() => ReactTooltip.show(copyUrlEl.current), 0);
    }

    function renderTooltip ()
    {
        return ReactDOMServer.renderToString(
            <div className="share-tooltip">
                <IconTwoArrows style={{transform : 'rotate(180deg)', marginRight: 5}}/>
                <span>
					{t("premadesDialog.afterPremadeCopyTooltip")}
				</span>
            </div>
        );
    }

    return (
        <div onDoubleClick={() => addPremadeOnCanvas(premade)} className="premade w-50 mb-5" key={premade.id}>
            <div className="d-flex justify-content-between mb-2">
                <span className="name">{premade.name}</span>
                <div>
                    <span ref={copyUrlEl} data-tip={renderTooltip()} data-place="right" style={{position: 'relative', right: -15, top: -5}}></span>
                    {
                        !premade.svg &&
                        <CopyToClipboard text={getPremadeShareURL(premade)} onCopy={copyPremadeUrl}>
                            <IconLink width={20} height={20} className="hover-opacity mr-2" fill="#25f7f0"/>
                        </CopyToClipboard>
                    }
                    <IconAdd width={20} height={20} onClick={addPremade} className="hover-opacity" fill="#25f7f0"/>
                </div>
            </div>
            <div className="premade-image-wrapper">
                {
                    premade.base64 &&
                    <img className="premade-image" src={premade.base64}/>
                }
                {
                    premade.svg &&
                    <LazyLoadImage
                        src={'/assets/svg/' + premade.name + '.svg'}
                        className="premade-image" placeholder={<Loader/>}/>
                }
            </div>
            {
                premade.createdByName &&
                <div className="by mt-2">
                    <IconUser style={{verticalAlign: -3}} width={16} height={16}/> By: {premade.createdByName}
                </div>
            }
        </div>
    );
}
