import {fabric} from "fabric";
import {snapToGrid} from "./draw";

export const gridSize = 1;

export function initGrid (canvas)
{
    drawGrid(canvas);
    initMovingListener(canvas);
    initScaleListener(canvas);
    initRotateListener(canvas);
}


function drawGrid (canvas)
{
    //canvas.getObjects().filter(object => object.name === "grid").map(object => canvas.remove(object));

    const currentCanvasWidth = canvas.getWidth();
    const currentCanvasHeight = canvas.getHeight();

    const lineOption = {
        stroke: '#f03678',
        strokeWidth: 1,
        selectable: false,
        hoverCursor: 'default',
        strokeDashArray: [0, gridSize],
        strokeLineCap : 'round',
        excludeFromExport : true,
        name : "grid",
        opacity: 0.25,
        evented : false,
        lockMovementX : true,
        lockMovementY : true,
        lockRotation : true,
        lockScalingFlip : true,
        lockScalingX : true,
        lockScalingY : true,
        lockSkewingX : true,
        lockSkewingY : true,
        noScaleCache : true,
        objectCaching : true,
    };

    for (let i = Math.ceil(currentCanvasWidth / gridSize); i--;)
    {
        let object = new fabric.Line([gridSize * i, 0, gridSize * i, currentCanvasHeight], lineOption);
        canvas.add(object);
        canvas.moveTo(object, 0);
    }
}

function initMovingListener (canvas)
{
    canvas.on('object:moving', function(options)
    {
        let addition = 0;

        if (options.target.type === "rotatingLine" || options.target.type === "arrowLine")
        {
            addition = -1.5;
        }

        options.target.set({
            left: snapToGrid(options.target.left, gridSize) + addition,
            top: snapToGrid(options.target.top, gridSize ) + addition
        });

        canvas.clearContext(canvas.contextTop);
    });
}

function initScaleListener (canvas)
{
    canvas.on('object:scaling', options =>
    {
        let target = options.target,
            w = target.width * target.scaleX,
            h = target.height * target.scaleY,
            snap = {
                top: snapToGrid(target.top),
                left: snapToGrid(target.left),
                bottom: snapToGrid(target.top + h),
                right: snapToGrid(target.left + w)
            },
            threshold = gridSize,
            dist = {
                top: Math.abs(snap.top - target.top),
                left: Math.abs(snap.left - target.left),
                bottom: Math.abs(snap.bottom - target.top - h),
                right: Math.abs(snap.right - target.left - w)
            },
            attrs = {
                scaleX: target.scaleX,
                scaleY: target.scaleY,
                top: target.top,
                left: target.left
            },
            angle = target.angle;

        switch (target.__corner) {
            case 'tl':
                attrs.scaleX = (w - (snap.left - target.left)) / target.width;
                attrs.scaleY = (h - (snap.top - target.top)) / target.height;
                attrs.top = target.top + (h - target.height * attrs.scaleY);
                attrs.left = attrs.left + (w - target.width * attrs.scaleX);
                break;
            case 'mt':
                attrs.scaleY = (h - (snap.top - target.top)) / target.height;
                attrs.top = snap.top;
                break;
            case 'tr':
                attrs.scaleX = (snap.right - target.left) / target.width;
                attrs.scaleY = (h - (snap.top - target.top)) / target.height;
                attrs.top = snap.top;
                break;
            case 'ml':
                attrs.scaleX = (w - (snap.left - target.left)) / target.width;
                attrs.left = snap.left;
                break;
            case 'mr':
                attrs.scaleX = (snap.right - target.left) / target.width;
                break;
            case 'bl':
                attrs.scaleX = (w - (snap.left - target.left)) / target.width;
                attrs.scaleY = (snap.bottom - target.top) / target.height;
                attrs.left = attrs.left + (w - target.width * attrs.scaleX);
                break;
            case 'mb':
                attrs.scaleY = (snap.bottom - target.top) / target.height;
                break;
            case 'br':
                attrs.scaleX = (snap.right - target.left) / target.width;
                attrs.scaleY = (snap.bottom - target.top) / target.height;
                break;
        }

        if (angle !== 0)
        {
            attrs.top = target.top;
            attrs.left = target.left;
        }

        target.set(attrs);
        canvas.clearContext(canvas.contextTop);
    })

}

export function getObjectsCount (canvas)
{
    return canvas.getObjects().filter(object => object.name !== "grid").length;
}

function initRotateListener (canvas)
{
/*
    canvas.on('object:rotate', options =>
    {
        options.target.set({snapAngle : 90});
    })
*/

}
