import {shallowEqual, useSelector} from "react-redux";
import {useCallback} from "react";

export function useArrowsMove ()
{
	const canvas = useSelector(state => state.canvas.canvas, shallowEqual);
	const activeObject = useSelector(state => state.canvas.activeObject, shallowEqual);

	function updateCanvas ()
	{
		activeObject.setCoords();
		canvas.clearContext(canvas.contextTop);
		canvas.requestRenderAll();
		canvas.trigger('object:modified', {target: activeObject});
	}

	const moveArrowUp = useCallback((withShift) =>
	{
		if (!activeObject)
		{
			return;
		}

		activeObject.set({top : activeObject.top - (withShift ? 10 : 1)});
		updateCanvas();

	}, [canvas, activeObject]);

	const moveArrowDown = useCallback((withShift) =>
	{
		if (!activeObject)
		{
			return;
		}

		activeObject.set({top : activeObject.top + (withShift ? 10 : 1)});
		updateCanvas();

	}, [canvas, activeObject]);

	const moveArrowLeft = useCallback((withShift) =>
	{
		if (!activeObject)
		{
			return;
		}

		activeObject.set({left : activeObject.left - (withShift ? 10 : 1)});
		updateCanvas();

	}, [canvas, activeObject]);

	const moveArrowRight = useCallback((withShift) =>
	{
		if (!activeObject)
		{
			return;
		}

		activeObject.set({left : activeObject.left + (withShift ? 10 : 1)});
		updateCanvas();

	}, [canvas, activeObject]);

	return {moveArrowUp, moveArrowDown, moveArrowRight, moveArrowLeft};
}
