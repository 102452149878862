import React from 'react';
import {IconEdit, IconOpenExternal, IconUser} from "./icons";
import {setEditableIdea} from "../ducks/ideas";
import CryptoJS from "crypto-js/crypto-js";
import {KEY} from "../logic/share";

export default function IdeaItem ({idea})
{

    function openIdea ()
    {
        const encryptedId = CryptoJS.AES.encrypt(idea.id, KEY).toString();
        window.open(`${window.location.origin}/share/${encryptedId}`, '_blank');
    }

    function editIdea ()
    {
        setEditableIdea(idea);
    }

    return (
        <div onDoubleClick={openIdea} className="idea w-50 mb-5" key={idea.id}>
            <div className="d-flex justify-content-between mb-2">
                <span className="name">{idea.name}</span>
                <div>
                    <IconEdit width={20} height={20} onClick={editIdea} className="hover-opacity" fill="#25f7f0"/>
                    <IconOpenExternal width={20} height={20} onClick={openIdea} className="hover-opacity ml-2" fill="#25f7f0"/>
                </div>
            </div>
            <div className="idea-image-wrapper">
                <img className="idea-image" src={idea.base64}/>
            </div>
            {
                idea.createdByName &&
                <div className="by mt-2">
                    <IconUser style={{verticalAlign: -3}} width={16} height={16}/> By: {idea.createdByName}
                </div>
            }
        </div>
    );
}
