import * as React from "react";

function SvgIconTextSize(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <style>
        {".IconTextSize_svg__st1{font-family:&apos;Nunito-Regular&apos;}"}
      </style>
      <text
        transform="translate(14 394)"
        className="IconTextSize_svg__st1"
        fontSize={401.656}
        fill="#00e9df"
        id="IconTextSize_svg__Layer_3"
      >
        {"A"}
      </text>
      <text
        transform="translate(293.603 394)"
        className="IconTextSize_svg__st1"
        fontSize={275.527}
        fill="#00d5f4"
        id="IconTextSize_svg__Layer_2"
      >
        {"A"}
      </text>
    </svg>
  );
}

export default SvgIconTextSize;
