import * as React from "react";

function SvgIconAddHorizontalGuideline(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <linearGradient
        id="IconAddHorizontalGuideline_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={245.283}
        y1={306.356}
        x2={245.283}
        y2={221.355}
        gradientTransform="matrix(-3.4351 -3.1308 -3.1048 3.4065 1915.741 127.43)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M14 256.7c.1 7.5 6.3 13.8 13.8 13.8l456.4-1.2c7.5 0 13.8-6.1 13.7-13.7-.2-3.9-1.7-7.8-4.5-10.3-2.4-2.2-6.1-3.7-9.4-3.5l-456.1 1.5c-7.7-.3-14 5.8-13.9 13.4z"
        fill="url(#IconAddHorizontalGuideline_svg__a)"
      />
    </svg>
  );
}

export default SvgIconAddHorizontalGuideline;
