import React, {useEffect} from 'react';
import Modal from 'react-modal';
import {shallowEqual, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import AuthButton from "../components/AuthButton";
import {setNotLoggedForNewIdeaModalOpened} from "../ducks/ideas";

Modal.defaultStyles.overlay.backgroundColor = '#00000060';
Modal.setAppElement('#root');

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: 0,
		maxWidth: 600
	},
	overlay: {
		zIndex: 10
	}
};

export default function NotLoggedForNewIdeaModal ({})
{
	const user = useSelector(state => state.main.user, shallowEqual);
	const notLoggedForNewIdeaModalOpened = useSelector(state => state.ideas.notLoggedForNewIdeaModalOpened, shallowEqual);

	const {t} = useTranslation();

	useEffect(() =>
	{
		if (user)
		{
			close();
		}
	}, [user]);

	function close ()
	{
		setNotLoggedForNewIdeaModalOpened(false);
	}

	return (
	    <Modal
		    isOpen={notLoggedForNewIdeaModalOpened}
		    style={customStyles}
		    onAfterOpen={() => false}
		    onRequestClose={close}
	    >

		    <div className="modal">

			    <div className="modal-header">
				    <span className="title">{t("notLoggedForNewIdeaModal.title")}</span>
			    </div>
			    <div className="modal-body d-block">
					<p className="mb-4">{t("notLoggedForNewIdeaModal.text1")}</p>

					<p className="mb-0">{t("notLoggedForNewIdeaModal.text2")}</p>

					<br/>

					<AuthButton/>

			    </div>
		    </div>
	    </Modal>
    )
}
