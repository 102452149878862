import React from 'react';


export default function Logo ({})
{
    return (
        <b className="logo">
            wdyt<span className="question">?</span>
        </b>
    );
}
