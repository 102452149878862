import * as React from "react";

function SvgIconTwoArrows(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={24}
      height={24}
      {...props}
    >
      <linearGradient
        id="IconTwoArrows_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={67.45}
        y1={21.7}
        x2={67.45}
        y2={80.3}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M82.7 48.9L56.4 22.6c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2L76.4 51 52.2 75.2c-1.2 1.2-1.2 3.1 0 4.2.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l26.3-26.3c1.2-1.2 1.2-3 0-4.2z"
        fill="url(#IconTwoArrows_svg__a)"
      />
      <linearGradient
        id="IconTwoArrows_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={30.55}
        y1={21.7}
        x2={30.55}
        y2={80.3}
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M19.5 22.6c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2L39.5 51 15.3 75.2c-1.2 1.2-1.2 3.1 0 4.2.6.6 1.4.9 2.1.9.8 0 1.5-.3 2.1-.9l26.3-26.3c.6-.6.9-1.3.9-2.1s-.3-1.6-.9-2.1L19.5 22.6z"
        fill="url(#IconTwoArrows_svg__b)"
      />
    </svg>
  );
}

export default SvgIconTwoArrows;
