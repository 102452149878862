import React from 'react';


export default function Loader ({})
{
    return (
        <div className="loader">
            <div className="shadow"></div>
            <div className="loading-animation">
                <div></div>
            </div>
        </div>
    );
}
