import * as React from "react";

function SvgIconResize(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={512}
      height={512}
      {...props}
    >
      <linearGradient
        id="IconResize_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={76}
        y1={93.5}
        x2={76}
        y2={4.11}
        gradientTransform="matrix(1 0 0 -1 0 104)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M91.9 12.5c0-.1 0-.1 0 0-.1-.2-.2-.4-.3-.5-.1-.2-.2-.3-.4-.5-.1-.1-.3-.3-.5-.4 0-.1-.2-.1-.3-.2-.1 0-.1-.1-.2-.1-.1-.1-.3-.1-.4-.1h-.2c-.2 0-.4-.1-.6-.1l-26.1.2c-1.7 0-3 1.4-3 3s1.4 3 3 3l18.9-.1-21 21c-1.2 1.2-1.2 3.1 0 4.2.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9l21-21-.1 18.9c0 1.7 1.3 3 3 3 1.6 0 3-1.3 3-3l.2-26.2c0-.4-.1-.8-.2-1.1z"
        fill="url(#IconResize_svg__a)"
      />
      <linearGradient
        id="IconResize_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={76.05}
        y1={93.5}
        x2={76.05}
        y2={4.11}
        gradientTransform="matrix(1 0 0 -1 0 104)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M91.9 67.6c0-1.6-1.4-3-3-3-1.7 0-3 1.4-3 3l.1 18.9-21-21c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2l21 21-18.9-.1c-1.7 0-3 1.3-3 3s1.3 3 3 3l26.2.2c.2 0 .4 0 .6-.1.1 0 .1 0 .2-.1.1 0 .3-.1.4-.1s.1-.1.2-.1c.1-.1.2-.1.3-.2.2-.1.3-.2.5-.4.1-.1.3-.3.4-.5.3-.5.5-1.1.5-1.7l-.3-26z"
        fill="url(#IconResize_svg__b)"
      />
      <linearGradient
        id="IconResize_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={22.7}
        y1={93.5}
        x2={22.7}
        y2={4.11}
        gradientTransform="matrix(1 0 0 -1 0 104)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M35.7 15.4c1.6 0 3-1.3 3-3s-1.3-3-3-3L9.5 9.2c-.2 0-.4 0-.6.1h-.2c-.1 0-.3.1-.4.1-.1 0-.1.1-.2.1 0 .1-.2.1-.3.2-.1.1-.3.2-.4.4-.2.1-.3.3-.4.4-.1.2-.2.4-.2.5v.1c-.2.4-.2.8-.2 1.1l.2 26.2c0 1.6 1.4 3 3 3 1.7 0 3-1.4 3-3l-.1-18.9 21 21c.6.6 1.4.9 2.1.9s1.5-.3 2.1-.9c1.2-1.2 1.2-3.1 0-4.2l-21-21 18.8.1z"
        fill="url(#IconResize_svg__c)"
      />
      <linearGradient
        id="IconResize_svg__d"
        gradientUnits="userSpaceOnUse"
        x1={22.05}
        y1={93.5}
        x2={22.05}
        y2={4.11}
        gradientTransform="matrix(1 0 0 -1 0 104)"
      >
        <stop offset={0} stopColor="#00efd1" />
        <stop offset={1} stopColor="#00acea" />
      </linearGradient>
      <path
        d="M37.2 65.5c-1.2-1.2-3.1-1.2-4.2 0l-21 21 .1-18.9c0-1.7-1.3-3-3-3-1.6 0-3 1.3-3 3l-.2 26.2c0 .6.2 1.2.5 1.7.1.2.2.3.4.5.1.1.3.3.5.4.1.1.2.1.3.2.1 0 .1.1.2.1.1.1.3.1.4.1.1 0 .1 0 .2.1.2 0 .4.1.6.1l26.2-.2c1.7 0 3-1.4 3-3s-1.4-3-3-3l-18.9.1 21-21c1.1-1.4 1.1-3.3-.1-4.4z"
        fill="url(#IconResize_svg__d)"
      />
    </svg>
  );
}

export default SvgIconResize;
