import * as React from "react";

function SvgIconPremade(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 2 98 98"
      width={24}
      height={24}
      {...props}
    >
      <linearGradient
        id="IconPremade_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={49.05}
        y1={11.5}
        x2={49.05}
        y2={95.753}
      >
        <stop offset={0} stopColor="#eaff00" />
        <stop offset={1} stopColor="#a9ab00" />
      </linearGradient>
      <path
        d="M90.1 12.6H7.9c-1.7 0-3 1.3-3 3v21.2c0 1.7 1.3 3 3 3h3.7v50.6c0 1.7 1.3 3 3 3h69c1.7 0 3-1.3 3-3V39.8h3.6c1.7 0 3-1.3 3-3V15.6c-.1-1.7-1.4-3-3.1-3zm-9.5 74.8h-63V39.8h63v47.6zm6.6-53.6H10.9V18.6h76.3v15.2z"
        fill="url(#IconPremade_svg__a)"
      />
      <linearGradient
        id="IconPremade_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={49.1}
        y1={11.5}
        x2={49.1}
        y2={95.753}
      >
        <stop offset={0} stopColor="#eaff00" />
        <stop offset={1} stopColor="#a9ab00" />
      </linearGradient>
      <path
        d="M31.4 67.7h35.4c1.7 0 3-1.3 3-3V50.4c0-1.7-1.3-3-3-3H31.4c-1.7 0-3 1.3-3 3v14.3c0 1.7 1.3 3 3 3zm3-14.3h29.4v8.3H34.4v-8.3z"
        fill="url(#IconPremade_svg__b)"
      />
    </svg>
  );
}

export default SvgIconPremade;
